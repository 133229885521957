import validate from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-auth": () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/middleware/check-auth.ts")
}