import { userSessionState } from "@aschehoug/aschehoug-oidc-client";

/**
 * Global middleware that ensures that, if the user is authenticated, a cookie is populated with a valid access token.
 *
 * Will also fetch user data from the backend if the user is authenticated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const token = useCookie("__accessToken");

  if (token.value && isExpired(token.value)) {
    token.value = null;
  }

  if (!token.value && import.meta.client) {
    try {
      const client = useOidcClient();
      const res = await client.startAuthSession();
      if (res === userSessionState.hasActiveSession) {
        let newToken = client.getAccessToken();
        if (isExpired(newToken) && !!client.getAmTokenData()?.refresh_token) {
          try {
            const state = await client.refreshAccessToken();
            if (state === userSessionState.hasActiveSession) {
              newToken = client.getAccessToken();
            }
          } catch (e) {
            handleError(e, "Error refreshing access token", false);
          }
          token.value = isExpired(newToken) ? null : newToken;
        } else if (newToken) {
          token.value = newToken;
        }
      } else {
        token.value = null;
      }
    } catch (e) {
      handleError(e, "Error starting auth session", false);
    }
  }

  const { time } = useServerTiming();

  if (token.value) {
    const auth = useAuthorizeStore();
    const cart = useCartStore();
    cart.setCurrentRoute(to);

    auth.accessToken = token.value;

    async function loadData() {
      try {
        await time("fetch_user", () => auth.fetchUserData());
      } catch (e) {
        handleError(e, "Error fetching user data", false);
      }
    }

    await callOnce(loadData);
  }
});
