<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faBagShopping, faFaceSmile } from "@fortawesome/pro-solid-svg-icons";

const cart = useCartStore();

const auth = useAuthorizeStore();

const showSidebar = ref(false);

useRouter().beforeEach(() => {
  showSidebar.value = false;
});
</script>

<template>
  <header class="sticky top-0 z-50 h-24 w-screen border-b border-b-green-pop bg-green-50 print:hidden">
    <div class="mx-auto flex h-full w-content max-w-[calc(100vw-3rem)] items-center justify-between gap-4">
      <NuxtLink
        to="/"
        class="w-fit [&>svg]:w-36 xs:[&>svg]:w-auto"
        aria-label="Aschehoug logo"
      >
        <IconsToolbarLogo fill="white" aria-hidden="true" />
      </NuxtLink>
      <nav class="flex items-center gap-6 text-white xs:gap-10">
        <ClientOnly>
          <NuxtLink
            :to="
              auth.loggedIn
                ? '/minside'
                : `/login?redirectTo=${encodeURIComponent('/minside')}`
            "
            class="flex"
            aria-label="min profil"
          >
            <KsIcon :icon="faFaceSmile" class="!size-6 xs:!size-7" />
          </NuxtLink>
          <template #fallback>
            <KsIcon :icon="faFaceSmile" class="!size-6 xs:!size-7" />
          </template>
        </ClientOnly>
        <NuxtLink
          to="/handlekurv"
          class="relative flex"
          :aria-label="
            cart.isEmpty
              ? 'handlekurv'
              : `handlekurv, det finnes ${cart.count} produkter i handlekurven din`
          "
        >
          <KsIcon :icon="faBagShopping" class="!size-6 xs:!size-7" />
          <ClientOnly>
            <span
              v-if="!cart.isEmpty"
              class="absolute -bottom-3 right-3 flex size-5 items-center justify-center rounded-full bg-yellow-40 text-xs font-semibold text-black-80 xs:-bottom-4 xs:right-4 xs:size-7"
              aria-live="polite"
              :aria-label="`Totalt ${cart.count} produkter i handlekurven`"
              data-dd-privacy="mask"
              v-text="cart.count"
            />
          </ClientOnly>
        </NuxtLink>
        <button
          :aria-expanded="showSidebar"
          aria-haspopup="true"
          class="flex"
          aria-label="Åpne sidebar"
          @click="showSidebar = !showSidebar"
        >
          <KsIcon :icon="faBars" class="!size-[1.85rem] xs:!size-[2.3125rem]" />
        </button>
        <LayoutSidebar
          v-if="showSidebar"
          v-model:open="showSidebar"
          aria-expanded="true"
        />
      </nav>
    </div>
  </header>
</template>
