import type { GetCartMetadataQuery } from "#gql/default";
import type { PacketTrialCard } from "#shared/types/universe";
import type { Crystallize } from "#shared/utils/crystallize-spec";
import { SUBJECT_CODES } from "#shared/types/core/common";

export function toPacket(child: Page | ItemRelation): Packet {
  const subscription: Packet = {
    type: "packet",
    id: child.id,
    name: child.name ?? "",
    title: "",
    cover: { img: "", alt: "" },
    variants: [],
    formats: ["nettsted"],
    tax: getTax(child),
    videoAdv: { title: "", url: "" },
    relatedArticles: [],
    slug: pathToSlug(child.path ?? ""),
    theme: "green-seagreen",
    isCanonical: child.pathResolutionMethod === "canonical",
    pitches: [],
  };

  if (child.components) {
    for (const component of child.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"aunivers-abonnement">;

      switch (id) {
        case "forsidebilde":
          subscription.coverCard = getSingleImage(component, 500);
          break;
        case "logo":
          subscription.logo = getSingleImage(component, "raw");
          break;
        case "navn": {
          const name = getSingleLine(component);
          subscription.name = name;
          subscription.slug.custom = slugify(name);
          break;
        }
        case "tittel":
          subscription.title = getSingleLine(component);
          break;
        case "trinn":
          subscription.grades = getEnumValues(component, GRADES, ["aarstrinn"]);
          subscription.levels = subscription.grades
            .map((it) => GRADE_TO_LEVEL[it])
            .filter(truthyAndDistinct);
          break;
        case "videoadv": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "aunivers-abonnement",
              "videoadv"
            >;

            switch (id) {
              case "tittel":
                subscription.videoAdv.title = getSingleLine(chunk);
                break;
              case "url":
                subscription.videoAdv.url = getSingleLine(chunk);
                break;
            }
          }
          break;
        }
        case "fargetema": {
          const selection = getSelections(component, "value")[0];
          const theme = PACKET_THEMES.find((it) => it === selection);
          if (theme) {
            subscription.theme = theme;
          }
          break;
        }
        case "fargekort":
          subscription.cardColor = getSelections(
            component,
            "value",
          )[0] as Crystallize.PacketCardColor;
          break;

        case "kampanje": {
          const itemRelation = getItemRelations(component)?.[0];
          if (itemRelation) {
            subscription.campaign = toCampaign(itemRelation);
          }
          break;
        }

        case "related-articles": {
          subscription.relatedArticles =
            getItemRelations(component).map(toArticle);
          break;
        }

        case "quote": {
          const components = getNestedComponents(component);

          let text: string | undefined;
          const author: { name: string; role?: string } = { name: "" };

          for (const part of components) {
            switch (part.id) {
              case "author": {
                const chunks = getSingleChunks(part);

                for (const chunk of chunks) {
                  switch (chunk.id) {
                    case "name": {
                      author.name = getSingleLine(chunk);
                      break;
                    }
                    case "role": {
                      author.role = getSingleLine(chunk);
                      break;
                    }
                  }
                }
                break;
              }
              case "text": {
                text = getSingleLine(part);
                break;
              }
            }
          }

          if (text) {
            subscription.quote = {
              text,
            };

            if (author.name) {
              subscription.quote.author = author;
            }
          }
          break;
        }

        case "pitches": {
          const chunks = getRepeatableChunks(component)
            .flatMap((chunks) => chunks.filter((it) => it.id === "data"))
            .map(getNestedComponents);

          for (const fields of chunks) {
            const pitch: PacketPitch = {
              title: "",
              text: "",
            };

            for (const field of fields) {
              switch (field.id) {
                case "title":
                  pitch.title = getSingleLine(field);
                  break;
                case "text":
                  pitch.text = getRichText(field);
                  break;
                case "image":
                  pitch.image = getSingleImage(field);
                  break;
                case "link": {
                  const linkChunks = getSingleChunks(field);
                  const link: PacketPitch["link"] = { url: "" };
                  for (const chunk of linkChunks) {
                    switch (chunk.id) {
                      case "label":
                        link.label = getSingleLine(chunk);
                        break;
                      case "url":
                        link.url = getSingleLine(chunk);
                        break;
                    }
                  }

                  if (link.url) {
                    pitch.link = link;
                  }
                  break;
                }
              }
            }

            if (pitch.title && pitch.text) {
              subscription.pitches.push(pitch);
            }
          }

          break;
        }

        case "format": {
          const values = getSelections(component);
          subscription.format = values[0];
          break;
        }

        case "trial-card": {
          const trialCard: Partial<PacketTrialCard> = {};
          for (const chunk of getSingleChunks(component)) {
            switch (chunk.id) {
              case "title":
                trialCard.title = getSingleLine(chunk);
                break;
              case "text":
                trialCard.text = getRichText(chunk);
                break;
              case "variant": {
                trialCard.variant = getSkus(chunk)[0];
                break;
              }
            }
          }
          if (trialCard.title && trialCard.variant) {
            subscription.trialCard = trialCard as PacketTrialCard;
          }
          break;
        }

        case "related-cta": {
          subscription.relatedCta ??= {
            products: [],
          };

          const chunks = getSingleChunks(component);

          for (const chunk of chunks) {
            const id = chunk.id as Crystallize.ChunkId<"aunivers-abonnement", "related-cta">;

            switch (id) {
              case "title": {
                subscription.relatedCta.title = getSingleLine(chunk);
                break;
              }
              case "products": {
                const products = getItemRelations(chunk);
                subscription.relatedCta.products = products.map(toPacketOrProduct).filter(truthy);
                break;
              }
              case "card-title": {
                subscription.relatedCta.card ??= {};
                subscription.relatedCta.card.title = getSingleLine(chunk);
                break;
              }
              case "card-link-href": {
                subscription.relatedCta.card ??= {};
                subscription.relatedCta.card.link ??= {};
                subscription.relatedCta.card.link.href = getSingleLine(chunk);
                break;
              }
              case "card-link-label": {
                subscription.relatedCta.card ??= {};
                subscription.relatedCta.card.link ??= {};
                subscription.relatedCta.card.link.label = getSingleLine(chunk);
                break;
              }
            }
          }

          break;
        }

        case "independent-variants": {
          const components = getNestedComponents(component);

          let title: string | undefined, text: string | undefined;

          for (const component of components) {
            switch (component.id) {
              case "title":
                title = getSingleLine(component);
                break;
              case "text":
                text = getRichText(component);
                break;
            }
          }

          if (title || text) {
            subscription.independentVariantsMeta = {
              title,
              text,
            };
          }

          break;
        }
      }
    }
  }

  if ("variants" in child && Array.isArray(child.variants)) {
    subscription.variants = child.variants.map((variant) =>
      toPacketVariant(variant, subscription),
    ).filter((it) => !it.outOfPrint);
  }

  return subscription;
}

type RawVariant =
  | NonNullable<Extract<Page, { variants?: any }>["variants"]>[number]
  | NonNullable<GetCartMetadataQuery["productVariants"][number]>;

export function toPacketVariant(
  variant: RawVariant,
  parent: Pick<Packet, "slug" | "type" | "title" | "tax">,
) {
  const cover = variant.images?.[0];
  const prices = getPrices(variant.priceVariants, variant.price as IncVat);

  const subscriptionVariant: PacketVariant = {
    parentSlug: parent.slug,
    parentType: parent.type,
    parentTitle: parent.title,
    sku: variant.sku as ISBN,
    name: variant.name,
    isbn: variant.sku as ISBN,
    prices,
    included: false,
    relatedSubscription: [],
    fixedAmount: false,
    tax: parent.tax,
    isTrial: false,
    subscriptionsPlan: variant.subscriptionPlans
      ? handleSubscriptionPlans(variant.subscriptionPlans)
      : undefined,
    cover: cover?.url
      ? {
          img: cover.url,
          alt: cover.altText ?? "",
        }
      : undefined,
    isPurchasableByConsumers: false,
    outOfPrint: false,
  };

  if (variant.components) {
    for (const component of variant.components) {
      const id =
        component.id as Crystallize.VariantComponentId<"aunivers-abonnement">;

      switch (id) {
        case "navn":
          subscriptionVariant.customName = getSingleLine(component);
          break;
        case "tittel":
          subscriptionVariant.title = getSingleLine(component);
          break;
        case "subtitle":
          subscriptionVariant.subtitle = getSingleLine(component);
          break;
        case "subjects": {
          subscriptionVariant.subjects ??= [];
          subscriptionVariant.subjects.push(
            ...getEnumValues(component, SUBJECT_CODES),
          );
          break;
        }
        case "effective-vat-percent":
          subscriptionVariant.tax = Number(getSingleLine(component));
          break;
        case "componenttype":
          subscriptionVariant.isTrial =
            getSingleLine(component).toLowerCase() === "provelisens";
          /* TODO: This would break current functionality, but would also be correct. Investigate how to achieve this.
          subscriptionVariant.formats = getEnumValues(component, PRODUCT_FORMATS);
          */
          break;
        case "isbn": {
          const isbn = getSingleLine(component)?.trim();
          if (isbn) {
            // FIXME: Check that isbn is actually an ISBN
            subscriptionVariant.isbn = isbn as ISBN;
          }
          break;
        }
        case "beskrivelse":
          subscriptionVariant.description = getRichText(component);
          break;
        case "trinn":
          subscriptionVariant.grades = getEnumValues(component, GRADES, [
            "aarstrinn",
          ]);
          subscriptionVariant.levels = subscriptionVariant.grades
            .map((it) => GRADE_TO_LEVEL[it])
            .filter(truthyAndDistinct);
          break;
        case "fast-tilganger":
          subscriptionVariant.fixedAmount = getBoolean(component);
          break;
        case "tilknyttet":
          subscriptionVariant.included = getBoolean(component);
          break;
        case "tilknyttende-abonnement": {
          const relatedSubscription = getRepeatableChunks(component);
          subscriptionVariant.relatedSubscription = relatedSubscription
            .map((related) => {
              const value = related[0];
              return value ? getSingleLine(value) : "";
            })
            .filter(Boolean);
          break;
        }
        case "out-of-print":
          subscriptionVariant.outOfPrint = getBoolean(component);
          break;

        case "cover-image": {
          const img = getSingleLine(component);
          if (img) {
            subscriptionVariant.cover = {
              img,
              alt: subscriptionVariant.title ?? "",
            };
          }
          break;
        }
      }
    }
  }

  if (subscriptionVariant.isTrial) {
    subscriptionVariant.fixedAmount = true;
  }

  subscriptionVariant.isbn ||= subscriptionVariant.sku;

  return subscriptionVariant;
}
