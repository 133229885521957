import type { Crystallize } from "#shared/utils/crystallize-spec";

interface Card extends ArticleCard, Dated {
  hasPodcast: boolean;
}

type Predicate<T> = (item: T, index: number, array: T[]) => boolean;

export function useArticleCards(predicate: Predicate<Card> = () => true) {
  const version = useCrystallizeVersion();
  const { time } = useServerTiming();

  return useQuery<Card[]>({
    queryKey: ["article-cards", version],
    staleTime: 1000 * 60 * 60,
    select: (data) => data.filter(predicate),
    queryFn: async () => {
      const res = await time("GetArticleCards", () =>
        GqlGetArticleCards({
          language: "no-nb",
          version,
        }));

      const children = res.catalogue?.children ?? [];

      return children
        .map((child) => {
          const card: Card = {
            id: child.id,
            createdAt: new Date(child.createdAt),
            updatedAt: new Date(child.updatedAt),
            publishedAt: new Date(child.publishedAt),
            slug: pathToSlug(child.path ?? ""),
            title: child.name ?? "",
            levels: [],
            colorTheme: "green-seagreen",
            hasPodcast: false,
          };

          if (child.components) {
            for (const component of child.components) {
              if (!component.content) {
                continue;
              }

              const id =
                component.id as Crystallize.ComponentId<"artikkel-standard">;

              switch (id) {
                case "tittel":
                  card.title = getSingleLine(component);
                  card.slug.custom = slugify(card.title);
                  break;

                case "podkast":
                  card.hasPodcast ||= !!getSingleLine(component);
                  break;

                case "podkastepisoder": {
                  const relations = getItemRelations(component);
                  card.hasPodcast ||= !!relations.length;
                  break;
                }

                case "ekstra-informasjon": {
                  const chunks = getSingleChunks(component);

                  for (const chunk of chunks) {
                    if (!chunk.content) {
                      continue;
                    }

                    const id = chunk.id as Crystallize.ChunkId<
                      "artikkel-standard",
                      "ekstra-informasjon"
                    >;

                    switch (id) {
                      case "trinn":
                        card.levels = getLevels(chunk);
                        break;
                      case "tema":
                        card.theme = getSelections(chunk, "value");
                        break;
                      case "fag":
                        card.subject = getSelections(chunk, "value");
                        break;
                    }
                  }
                  break;
                }

                case "fargetema": {
                  const theme = getSelections(component)[0];
                  if (theme) {
                    card.colorTheme = theme as Crystallize.ArticleColorTheme;
                  }
                  break;
                }

                case "coverbilde":
                  card.cover = getSingleImage(component, 1366);
                  break;

                case "thumbnailbilde":
                  card.thumbnailImage = getSingleImage(component);
                  break;
              }
            }
          }

          return card;
        })
        .sort(byPublished());
    },
  });
}
