const path = "/innstillinger";

interface Seo {
  title?: string;
  description?: string;
}

type Some = Partial<Record<SocialProvider, string> & { image: string }>;

export interface Settings {
  updateSubscription: boolean;
  seo?: Seo;
  some?: Some;
}

const initialData = {
  updateSubscription: false,
} as const satisfies Settings;

export function useSettings() {
  const version = useCrystallizeVersion();

  return useQuery<Settings>({
    queryKey: [path, version],
    staleTime: 1000 * 60 * 10,
    placeholderData: initialData,
    async queryFn() {
      const res = await GqlGetPage({
        language: "no-nb",
        path,
        version,
      });

      const components = res.catalogue?.components ?? [];

      const data: Settings = {
        ...initialData,
      };

      for (const component of components) {
        switch (component.id) {
          case "update-subscription":
            data.updateSubscription = getBoolean(component);
            break;
          case "some": {
            const someChunks = getSingleChunks(component);
            data.some = {};
            for (const chunk of someChunks) {
              if (!chunk.content) {
                continue;
              }

              const text = getSingleLine(chunk);

              switch (chunk.id) {
                case "facebook-link":
                  data.some.facebook = text;
                  break;
                case "instagram-link":
                  data.some.instagram = text;
                  break;
                case "linkedin-link":
                  data.some.linkedin = text;
                  break;
                case "twitter-link":
                  data.some.x = text;
                  break;
                case "tiktok-link":
                  data.some.tiktok = text;
                  break;
                case "youtube":
                  data.some.youtube = text;
                  break;
                case "fallbackbilde-for-some":
                  data.some.image = getSingleImage(chunk, 500)?.img;
                  break;
              }
            }
            break;
          }
          case "fallback-seo": {
            const fallbackSeoChunks = getSingleChunks(component);
            data.seo = {};
            for (const chunk of fallbackSeoChunks) {
              if (!chunk.content) {
                continue;
              }

              const text = getSingleLine(chunk);
              switch (chunk.id) {
                case "generisk-seo-tittel":
                  data.seo.title = text;
                  break;
                case "generisk-seo-beskrivelse":
                  data.seo.description = text;
                  break;
              }
            }
            break;
          }
        }
      }

      return data;
    },
  });
}
