<script setup lang="ts">
const props = defineProps<{
  product: ProductCard<Packet | SingleProduct>;
}>();

const tag = computed(() => {
  const { subjects } = props.product;

  if (subjects.length === 0 || subjects.length >= 8) {
    return "Alle fag";
  }

  if (subjects.length <= 2) {
    return subjects
      .map((s) => {
        const labels = SUBJECT_LABELS[s];
        return Array.isArray(labels) ? labels[1] : labels;
      })
      .join("/");
  }

  return "Tverrfaglig";
});

const totStudents = useTotStudents();

const auth = useAuthorizeStore();

const showDiscount = computed(() =>
  props.product.variants
    .map((v) => calculatePrices(v, totStudents.value, auth.user?.source))
    .some((p) => p !== "NOT_APPLICABLE" && !!p.discounted),
);
</script>

<template>
  <CardsRelated :product="product" :tag="tag">
    <template #icon>
      <IconsDiscount
        v-if="showDiscount"
        size="small"
        aria-label="Produktet er på tilbud"
        class="[--background:white] [--color:theme(colors.coral.pop)] [--stroke:none]"
      />
    </template>
  </CardsRelated>
</template>
