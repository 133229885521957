import * as v from "valibot";

// Change this key if tempCartItemSchema is changed in a non-backwards compatible way
const TEMP_CART_KEY = "temp-cart-v1";
const tempCartItemSchema = v.pipe(
  v.nullable(v.string()),
  v.transform((it) => (typeof it === "string" ? JSON.parse(it) : it)),
  v.fallback(
    v.array(
      v.object({
        isbn: isbnSchema,
        amount: v.pipe(v.number(), v.minValue(1)),
      }),
    ),
    [],
  ),
);

export type TempCartItem = v.InferOutput<typeof tempCartItemSchema>[number];

export function useTempCart() {
  return useCookie<TempCartItem[]>(TEMP_CART_KEY, {
    maxAge: 60 * 10, // 10 minutes should be plenty
    default: () => [],
    encode: (it) => JSON.stringify(it),
    decode: (it) => {
      const parsed = v.safeParse(tempCartItemSchema, it);
      if (parsed.issues) {
        console.error(parsed.issues);
        return [];
      }

      return parsed.output ?? [];
    },
  });
}
