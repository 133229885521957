import payload_plugin_YdpKz0Ykq_p1poahlrqp9wwNdk90_RtM35MFUFZuMjE from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_H8bKya7j54Scqw7_slunmngJvVGuusvGWm5fiSM3DMo from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2XioAt55IA4q5l5X9qQO6uPS3lNipSQalJagJmprf3c from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bg1ayk9ZvFEK18QEPjDvkNqMjXiX3f6r8mQtkn1Q6tk from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_DhKZ3OZQeyGf4tSiTyWgCjkWZWe_XtWP_68i88LfEWM from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_tNmRbz_hYnrFc287k0v4LB_pMBjXL7zzm7hpdJmJR8o from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1yrPbYaNh4nfat8efVwn_EWTd_JKGy6o6hwAajbIInc from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aBWEOibikEiEy8mpYbvZH34tAYpvCgqBgJ_h5Qn34ic from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_t0fttJARPaw_MatQsYogMj_NzVzmgKVPkl_fU_rMU_w from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mD9i12qyLo_ZtCzzY9bDw5qoCVQZ9z_o8H_r0slDTxk from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/aschehoug/aschehoug-utdanning/shop-app/.nuxt/components.plugin.mjs";
import prefetch_client_5W_7hXfZ8nRikut51cQsSvduMPWavev3hlIfASoPa4M from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zTXTirwtjm6TkcHZ7dDKVnGgef6rOof8Gik_Dda7fI4 from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-graphql-client@0.2.43_patch_hash=hio5zje4phaudqdyn6x2dxv3he_@parcel+watcher@2.5.0_@types_tkjppjvwfjdshxmzhz2pf25czq/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import session_client_kBn_6BKpORxp46P9Jvu3WzEpeW3wBJdlouy7aPyj5F8 from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-auth-utils@0.5.16_magicast@0.3.5/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import siteConfig_5Uytt_InE0GxTVRIw2aVXAKa8pbZRmH8_IOUk_41wD8 from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_cQ7pwGkx1vJmP0nSv37WQrrUfQdVzVgxsTNvgzMsc_Y from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_aEickeeIVMi93__VwJehyukIKzmZYGHu2O5107mjnss from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import datadog_client_HImNUkxDT_OdAiYi7ZXU3Kn5ZFiVU9T7Iy0kZXPHX2g from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/plugins/datadog.client.ts";
import ks_toast_client_3MqTH7lvM6EMGXk89fzzC5FQ3agdLCfg3fGA5S_N14c from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/plugins/ks-toast.client.ts";
import error_handler_b94uaMSpE9w_QzuBnuJFETjvfxz_GY1_KR4D1ySwna0 from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/plugins/error-handler.ts";
import vue_masonry_wall_BeGSXOcSex_9jLvYqIQO_43gxbfd8xAKzvENeXYB5nY from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/plugins/vue-masonry-wall.ts";
import vue_query_mAdjlicKbpO1UnAZ7vXXXzGIhtuQpOlfwkLVjVkGKxE from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/plugins/vue-query.ts";
import _0_routeRules_AgRFrsphSR07q2F8SDiV6yBoZNNcZMxIUXqPbX9jk8E from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_NesDX0E9msPFcCrdx5bo1uOEQWsYW6U6G1FME8wvY_4 from "/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_YdpKz0Ykq_p1poahlrqp9wwNdk90_RtM35MFUFZuMjE,
  revive_payload_client_H8bKya7j54Scqw7_slunmngJvVGuusvGWm5fiSM3DMo,
  unhead_2XioAt55IA4q5l5X9qQO6uPS3lNipSQalJagJmprf3c,
  router_bg1ayk9ZvFEK18QEPjDvkNqMjXiX3f6r8mQtkn1Q6tk,
  _0_siteConfig_DhKZ3OZQeyGf4tSiTyWgCjkWZWe_XtWP_68i88LfEWM,
  payload_client_tNmRbz_hYnrFc287k0v4LB_pMBjXL7zzm7hpdJmJR8o,
  navigation_repaint_client_1yrPbYaNh4nfat8efVwn_EWTd_JKGy6o6hwAajbIInc,
  check_outdated_build_client_aBWEOibikEiEy8mpYbvZH34tAYpvCgqBgJ_h5Qn34ic,
  chunk_reload_client_t0fttJARPaw_MatQsYogMj_NzVzmgKVPkl_fU_rMU_w,
  plugin_vue3_mD9i12qyLo_ZtCzzY9bDw5qoCVQZ9z_o8H_r0slDTxk,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_5W_7hXfZ8nRikut51cQsSvduMPWavev3hlIfASoPa4M,
  plugin_zTXTirwtjm6TkcHZ7dDKVnGgef6rOof8Gik_Dda7fI4,
  session_client_kBn_6BKpORxp46P9Jvu3WzEpeW3wBJdlouy7aPyj5F8,
  siteConfig_5Uytt_InE0GxTVRIw2aVXAKa8pbZRmH8_IOUk_41wD8,
  inferSeoMetaPlugin_cQ7pwGkx1vJmP0nSv37WQrrUfQdVzVgxsTNvgzMsc_Y,
  titles_aEickeeIVMi93__VwJehyukIKzmZYGHu2O5107mjnss,
  datadog_client_HImNUkxDT_OdAiYi7ZXU3Kn5ZFiVU9T7Iy0kZXPHX2g,
  ks_toast_client_3MqTH7lvM6EMGXk89fzzC5FQ3agdLCfg3fGA5S_N14c,
  error_handler_b94uaMSpE9w_QzuBnuJFETjvfxz_GY1_KR4D1ySwna0,
  vue_masonry_wall_BeGSXOcSex_9jLvYqIQO_43gxbfd8xAKzvENeXYB5nY,
  vue_query_mAdjlicKbpO1UnAZ7vXXXzGIhtuQpOlfwkLVjVkGKxE,
  _0_routeRules_AgRFrsphSR07q2F8SDiV6yBoZNNcZMxIUXqPbX9jk8E,
  defaults_NesDX0E9msPFcCrdx5bo1uOEQWsYW6U6G1FME8wvY_4
]