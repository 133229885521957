const BASE_PATH = "/produktkatalog";

export function useProductsQueryOptions(basePath = BASE_PATH) {
  const version = useCrystallizeVersion();

  const queryClient = useQueryClient();

  return queryOptions({
    queryKey: ["products", basePath, version],
    async queryFn() {
      const res = await GqlGetCatalogue({
        path: basePath,
        language: "no-nb",
        version,
      });

      const products: (Packet | SingleProduct)[] = [];

      const children = res.catalogue?.children ?? [];

      for (const child of children) {
        let product: Packet | SingleProduct | undefined;

        if (child?.shape?.identifier === "aunivers-abonnement") {
          product = toPacket(child);
        } else if (child?.shape?.identifier === "default-product") {
          product = toProduct(child);
        }

        if (product) {
          products.push(product);
          queryClient.setQueryData(["products", version, product.slug.short], product);
        }
      }

      return products;
    },
  });
}

export function useProducts(basePath = BASE_PATH) {
  return useQuery(useProductsQueryOptions(basePath));
}

export function useProduct(slug: MaybeRef<string | undefined | null>) {
  const version = useCrystallizeVersion();

  return useQuery({
    queryKey: ["products", BASE_PATH, version, slug],
    enabled: computed(() => !!unref(slug)),
    async queryFn() {
      const res = await GqlGetPage({
        path: `${BASE_PATH}/${unref(slug)}`,
        language: "no-nb",
        version,
      });

      if (res.catalogue?.shape?.identifier === "aunivers-abonnement") {
        return toPacket(res.catalogue);
      } else if (res.catalogue?.shape?.identifier === "default-product") {
        return toProduct(res.catalogue);
      }

      return null;
    },
  });
}
