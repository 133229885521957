import type { ProductSource } from "#shared/types/core/common";
import type {
  CartCheckoutRequest,
  CartDto,
  CartItemDto,
} from "#shared/types/core/ecommerce/cart";
import type { CheckoutResponse } from "#shared/types/core/ecommerce/checkout";

export function useCartApi() {
  const id = useState<UUID | undefined>("cartId", () => undefined);
  const api = useCoreApi("ecommerce/carts");
  const { origin } = useRequestURL();

  return {
    async getActiveOrNewCart(productSource?: ProductSource) {
      let url = api.rootUrl;
      if (productSource) {
        url += `?productSource=${productSource}`;
      }

      const cart = await api.$fetch<CartDto>(url);
      if (cart) {
        id.value = cart?.uuid;
      }
      return cart ?? null;
    },

    async deleteCart() {
      return await api.$fetch<void>(api.endpoint(unref(id)), {
        method: "DELETE",
      });
    },

    async addItemToCart(item: Omit<CartItemDto, "uuid">) {
      return await api.$fetch<CartDto>(api.endpoint(unref(id), "item"), {
        method: "POST",
        body: JSON.stringify(item),
      });
    },

    async deleteItemFromCart(itemId: UUID) {
      return await api.$fetch<CartDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "DELETE",
        },
      );
    },

    async updateCartItem({ uuid: itemId, ...item }: CartItemDto) {
      return await api.$fetch<CartItemDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "PUT",
          body: JSON.stringify(item),
        },
      );
    },

    async startSubscriptions(request: CartCheckoutRequest) {
      if (request.paymentSource !== "INVOICE") {
        throw new Error("Only INVOICE is supported at the moment");
      }

      return await api.$fetch<void>(
        api.endpoint(unref(id), "start-subscriptions"),
        {
          method: "POST",
          body: JSON.stringify(request),
        },
      );
    },

    async checkout({
      redirectUrl,
      ...request
    }: CartCheckoutRequest & {
      redirectUrl: string;
    }) {
      if (request.paymentSource !== "VIPPS") {
        throw new Error("Only VIPPS is supported at the moment");
      }

      const url = new URL(api.endpoint(unref(id), "checkout"), origin);
      url.searchParams.set("redirectUrl", redirectUrl);

      return await api.$fetch<CheckoutResponse>(url.href, {
        method: "POST",
        body: JSON.stringify(request),
      });
    },
  };
}
