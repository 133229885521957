import type { Crystallize } from "#shared/utils/crystallize-spec";

interface ArticleContent
  extends Omit<ArticleCard, "colorTheme" | "thumbnailImage"> {
  body: string[];
  intro?: string;
}

export function useArticleContent() {
  const version = useCrystallizeVersion();
  const { time } = useServerTiming();

  return useQuery<ArticleContent[]>({
    queryKey: ["article-content", version],
    staleTime: 1000 * 60 * 60,
    queryFn: async () => {
      const res = await time("GetArticleContent", () =>
        GqlGetArticleContent({
          language: "no-nb",
          version,
        }));

      const children = res?.catalogue?.children ?? [];
      return children.map((child) => {
        const article: ArticleContent = {
          id: child.id,
          slug: pathToSlug(child.path ?? ""),
          title: child.name ?? "",
          levels: [],
          body: [],
        };

        if (child.components) {
          for (const component of child.components) {
            if (!component.content) {
              continue;
            }

            const id =
              component.id as Crystallize.ComponentId<"artikkel-standard">;

            switch (id) {
              case "tittel":
                article.title = getSingleLine(component);
                article.slug.custom = slugify(article.title);
                break;

              case "ingress":
                article.intro = getRichText(component);
                break;

              case "tekst":
                article.body = getParagraphCollection(component);
                break;

              case "coverbilde":
                article.cover = getSingleImage(component, 1366);
                break;

              case "ekstra-informasjon": {
                const chunks = getSingleChunks(component);

                for (const chunk of chunks) {
                  if (!chunk.content) {
                    continue;
                  }

                  const id = chunk.id as Crystallize.ChunkId<
                    "artikkel-standard",
                    "ekstra-informasjon"
                  >;

                  switch (id) {
                    case "trinn":
                      article.levels = getLevels(chunk);
                      break;
                    case "tema":
                      article.theme = getSelections(chunk, "value");
                      break;
                    case "fag":
                      article.subject = getSelections(chunk, "value");
                      break;
                  }
                }
                break;
              }
            }
          }
        }

        return article;
      });
    },
  });
}
