import type { RumInitConfiguration } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";

const KEYS = [
  "applicationId",
  "clientToken",
  "version",
  "env",
  "service",
  "site",
] as const;

type DatadogConfig = Pick<RumInitConfiguration, (typeof KEYS)[number]>;

function isValidConfig(value: unknown): value is DatadogConfig {
  return (
    typeof value === "object" &&
    !!value &&
    KEYS.every((key) => {
      if (key in value) {
        const val = value[key as keyof typeof value];
        return typeof val === "string" && !!val;
      }

      return false;
    })
  );
}

interface CookieYesConsentEvent {
  detail: {
    accepted: string[];
    rejected: string[];
  };
}

interface CookieYesConsentData {
  activeLaw: string;
  categories?: {
    necessary: boolean;
    functional: boolean;
    analytics: boolean;
    performance: boolean;
    advertisement: boolean;
  };
  isUserActionCompleted: boolean;
  consentID: string;
  languageCode: string;
}

declare const window: Window & {
  // https://www.cookieyes.com/documentation/retrieving-consent-data-using-api-getckyconsent/
  getCkyConsent?: () => CookieYesConsentData;
};

/**
 * Add integration with CookieYes to update tracking consent if analytics is accepted by the user
 *
 * @see https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 */
function addCookieYesListener() {
  // @ts-expect-error - TS doesn't know about this event
  document.addEventListener("cookieyes_consent_update", (data: CookieYesConsentEvent) => {
    try {
      if (data.detail.accepted.includes("analytics")) {
        datadogRum.setTrackingConsent("granted");
      } else {
        datadogRum.setTrackingConsent("not-granted");
      }
    } catch (error) {
      console.error("Error in cookieyes_consent_update", error);
      datadogRum.setTrackingConsent("not-granted");
    }
  });
}

// TODO: Go through available options here https://docs.datadoghq.com/real_user_monitoring/browser/
export default defineNuxtPlugin(
  ({
    $config: {
      public: { datadog, urls },
    },
  }) => {
    if (!isValidConfig(datadog)) {
      console.warn("Invalid Datadog configuration", datadog);
      return;
    }

    const consent = window.getCkyConsent?.();

    const hasConsented = consent?.categories?.analytics;

    datadogRum.init({
      ...datadog,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      trackingConsent: (hasConsented || datadog.env === "test") ? "granted" : "not-granted",
      allowedTracingUrls: [
        window.location.origin,
        ...Object.values(urls.core),
        urls.crystallize,
      ],
    });

    addCookieYesListener();
  },
);
