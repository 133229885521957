import type { Campaign } from "#shared/utils/campaign";
import type { Crystallize } from "#shared/utils/crystallize-spec";
import type { ArticleType, ImageWithAltType } from "./articles";

export interface PacketVariant extends ProductVariant<"packet"> {
  customName?: string;
  included: boolean;
  relatedSubscription: string[];
}

export const PACKET_THEMES = [
  "green-seagreen",
  "dark-red-pink",
  "blue-pink",
  "green-red",
] as const satisfies ReadonlyArray<Crystallize.PacketColorTheme>;

export interface PacketPitch {
  title: string;
  text: string;
  image?: ImageWithAltType;
  link?: {
    label?: string;
    url: string;
  };
}

export interface PacketTrialCard {
  title: string;
  text?: string;
  variant: ProductVariantRelation;
}

export interface Packet extends Product<"packet", PacketVariant> {
  logo?: ImageWithAltType;
  videoAdv: VideoAdvType;
  campaign?: Campaign | null;
  relatedArticles: ArticleType[];
  pitches: PacketPitch[];
  theme: Crystallize.PacketColorTheme;
  format?: string;
  quote?: {
    text: string;
    author?: {
      name: string;
      role?: string;
    };
  };
  trialCard?: PacketTrialCard;
  relatedCta?: {
    title?: string;
    products: (Packet | SingleProduct)[];
    card?: {
      title?: string;
      link?: {
        label?: string;
        href?: string;
      };
    };
  };
  independentVariantsMeta?: {
    title?: string;
    text?: string;
  };
}

export interface VideoAdvType {
  title?: string;
  url?: string;
}
