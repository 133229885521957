import type { GetPageQuery } from "#gql";
import type { ItemRelation } from "#shared/utils/crystallize";
import type { Crystallize } from "#shared/utils/crystallize-spec";

export interface Campaign {
  id: string;
  show: boolean;
  title?: string;
  offerPercentage?: string;
  description?: string;
}

export function toCampaign(
  catalogue: GetPageQuery["catalogue"] | ItemRelation,
) {
  if (!catalogue) {
    return null;
  }

  const campaign: Campaign = {
    id: catalogue.id,
    show: false,
  };

  if (catalogue.components) {
    for (const component of catalogue.components) {
      const id = component.id as Crystallize.ComponentId<"kampanje">;

      switch (id) {
        case "show":
          campaign.show = getBoolean(component);
          break;
        case "tittel":
          campaign.title = getSingleLine(component);
          break;
        case "tilbud":
          campaign.offerPercentage = getSingleLine(component);
          break;
        case "beskrivelse":
          campaign.description = getRichText(component);
          break;
      }
    }
  }

  return campaign;
}
