import type { LocationQuery } from "vue-router";

/**
 * Create a filter function that filters by formats.
 *
 * @param query - The query object from the router, likely from `useRoute().query`.
 */
export function byFormats(query: LocationQuery) {
  const filter = normalizeQuery(query.format, PRODUCT_FORMATS);
  return <
    T extends {
      formats?: ProductFormat[];
      biblioFormats?: BiblioFormat[];
    },
  >(
    it: T,
  ): boolean => {
    if (filter.length === 0) {
      return true;
    }

    return (
      it.formats?.some((format) => filter.includes(format)) ||
      // "lærernettsted" is mapped to the "nettsted" product format, but "laererprodukt" filter should also include it.
      (filter.includes("laererprodukt") &&
        !!it.biblioFormats?.includes("lærernettsted"))
    );
  };
}

/**
 * Create a filter function that filters by grades.
 *
 * @param query - The query object from the router, likely from `useRoute().query`.
 * @param gradeOptions - Valid grade options.
 */
export function byGrades<T extends string>(
  query: LocationQuery,
  gradeOptions: Options<T, { grades?: GradeCode[] }>,
) {
  const filter = normalizeQuery(query.grade);

  return <T extends { grades?: GradeCode[] }>(it: T): boolean => {
    if (filter.length === 0) {
      return true;
    }

    const actualGrade = GRADES.find((grade) => filter.includes(grade));
    if (actualGrade) {
      return !!it.grades?.includes(actualGrade);
    }

    const validGrades = filter
      .flatMap<string>((grade) => {
        const option = gradeOptions.find(
          (it) => it.grades?.includes(grade as GradeCode) || it.value === grade,
        );

        return option?.grades ?? (option?.value ? [option.value] : []);
      })
      .filter(truthyAndDistinct);

    return !!it.grades?.some((g) => validGrades.includes(g));
  };
}

/**
 * Create a filter function that filters by subject category.
 *
 * @param query - The query object from the router, likely from `useRoute().query`.
 */
export function bySubjects(query: LocationQuery) {
  const filter = normalizeQuery(query.subject, SUBJECTS);

  return <T extends { subjectCategories: SubjectCategory[] }>(
    it: T,
  ): boolean => {
    if (filter.length === 0) {
      return true;
    }

    return it.subjectCategories.some((category) => filter.includes(category));
  };
}

/**
 * Create a filter function that filters by levels (1-7, 8-10 etc.).
 *
 * @param query - The query object from the router, likely from `useRoute().query`.
 */
export function byLevels(query: LocationQuery) {
  const levels = normalizeQuery(query.level, LEVELS);

  return <T extends { levels?: Level[] }>(it: T): boolean => {
    if (levels.length === 0) {
      return true;
    }

    return !!it.levels?.some((level) => levels.includes(level));
  };
}
