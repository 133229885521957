<script setup lang="ts">
const route = useRoute();
</script>

<template>
  <NuxtLink
    class="reset-link"
    :to="route.path"
    :replace="true"
  >
    Nullstill filtre
  </NuxtLink>
</template>

<style scoped lang="scss">
.reset-link {
  z-index: 1;
  align-self: flex-end;
  background: var(--filter-background);
  color: var(--foreground, theme("colors.seagreen.50"));
}
</style>
