import type { LocationQuery } from "vue-router";

export function normalizeQuery<S extends string = string>(
  query: LocationQuery[keyof LocationQuery] | undefined,
  enumValues?: readonly S[],
): S[] {
  let value = query ?? [];
  if (isString(value)) {
    value = [value];
  }

  return value
    .filter(isNonBlankString)
    .filter((s): s is S => !enumValues || enumValues.includes(s as S));
}
