<script setup lang="ts">
const props = defineProps<{
  products: Array<ProductCard<Packet | SingleProduct>>;
}>();

const filters = computed(() => {
  const validLevelOptions = new Set<LevelOption["value"]>();
  const validSubjectOptions = new Set<SubjectOption["value"]>();
  const validFormatOptions = new Set<ProductFormatOption["value"]>();

  for (const product of props.products) {
    if (product.levels) {
      for (const level of product.levels) {
        validLevelOptions.add(level);
      }
    }

    for (const subject of product.subjectCategories) {
      validSubjectOptions.add(subject);
    }

    for (const format of product.formats) {
      validFormatOptions.add(format);
    }
  }

  return [
    {
      name: "level",
      legend: "TRINN",
      enabled: validLevelOptions,
      options: LEVEL_OPTIONS,
    },
    {
      name: "subject",
      legend: "FAG",
      enabled: validSubjectOptions,
      options: SUBJECT_OPTIONS.filter(
        (it) => it.value !== "norwegian-foreigners",
      ),
    },
    {
      name: "format",
      legend: "PRODUKTTYPE",
      enabled: validFormatOptions,
      options: PRODUCT_FORMAT_OPTIONS,
    },
  ];
});
</script>

<template>
  <UiFilterContainer class="mx-auto w-[90%] bg-white px-8 py-4 sm:w-full sm:px-12 sm:py-8" :filters="filters" />
</template>
