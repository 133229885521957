import type { CartItemDto } from "#shared/types/core/ecommerce/cart";

export function useProductVariants() {
  const { data: products, suspense } = useProducts();

  // .map(...).flat() looks like a .flatMap(), but TS breaks for some reason
  const variants = computed(() => products.value?.map((it) => it.variants).flat() ?? []);

  function getBySku(sku: string) {
    return variants.value.find((it) => it.sku === sku || it.isbn === sku);
  }

  return {
    suspense,
    variants,
    getBySku,
  };
}

export function useProductVariant(
  isbnOrItemRef: MaybeRef<string | CartItemDto | undefined | null>,
) {
  const { getBySku } = useProductVariants();

  return computed(() => {
    const isbnOrItem = unref(isbnOrItemRef);

    if (!isbnOrItem) {
      return null;
    }

    return getBySku(typeof isbnOrItem === "string" ? isbnOrItem : isbnOrItem.productId);
  });
}
