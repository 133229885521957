<script setup lang="ts">
const emit = defineEmits(["focus"]);

const model = defineModel({
  default: "",
});
</script>

<template>
  <form class="w-full" data-form>
    <slot />
    <div class="mb-8 mt-4 flex items-stretch">
      <input
        id="searchfield"
        v-model="model"
        type="search"
        data-dd-privacy="mask"
        class="w-full rounded-l-[0.75rem] p-2 text-xl text-green-50 xs:p-4"
        @focus="emit('focus')"
      />
      <button
        data-search-button
        :aria-label="`søk på ${model} i hele nettsiden`"
        class="min-w-16 rounded-r-[0.75rem] bg-white text-[10px] hover:bg-green-35"
        type="submit"
      />
    </div>
  </form>
</template>

<style scoped>
[data-form] {
  max-width: min(90dvw, theme("spacing.content-article"));
}

[data-search-button] {
  background-image: url("/icons/SearchIcon.svg");
  background-size: 2.5rem;
  background-repeat: no-repeat;
  background-position: 60%;
}
</style>
