import type { InjectionKey, Ref } from "vue";
import type { CardVariantsClasses } from "~/components/packet/variant/card/styles";

export type ToastFn = (
  message:
    | string
    | {
      message?: string;
      target?: string;
      duration?: number;
      persistent?: boolean;
      buttons?: Array<{ text: string; onClick: () => void }>;
      onClose?: () => void;
    },
) => void;

export const keys = {
  toast: "ksToast" as unknown as InjectionKey<{
    success: ToastFn;
    error: ToastFn;
    info: ToastFn;
  }>,
  currentArticle: Symbol("currentArticle") as InjectionKey<
    ArticleType | Podcast
  >,
  currentPacket: Symbol("currentPacket") as InjectionKey<Readonly<Ref<Packet>>>,
  packetVariantCard: Symbol("packetVariantCard") as InjectionKey<Readonly<Ref<{
    classes: CardVariantsClasses;
    variants: PacketVariant[];
    primary?: PacketVariant;
    secondary?: PacketVariant;
  }>>>,
  currentProduct: Symbol("currentProduct") as InjectionKey<
    Ref<SingleProduct | undefined>
  >,
  packetQuantity: Symbol("packetQuantity") as InjectionKey<{
    min: ComputedRef<number>;
    quantity: Ref<number>;
  }>,
};
