export async function useFallbackSeo() {
  const { data: settings, suspense } = useSettings();

  await suspense();

  const route = useRoute();

  const {
    public: { siteUrl },
  } = useRuntimeConfig();

  const ogTitle = computed(() => withCompanyPrefix(settings.value?.seo?.title) ?? "");
  const ogDescription = computed(() => settings.value?.seo?.description);
  const ogImage = computed(() => settings.value?.some?.image);

  useSeoMeta({
    title: ogTitle,
    ogTitle,
    description: ogDescription,
    ogDescription,
    ogLocale: "no",
    ogSiteName: COMPANY_NAME,
    ogUrl: computed(() => siteUrl + route.path),
    ogImage,
    twitterCard: "summary_large_image",
    twitterTitle: ogTitle,
    twitterDescription: ogDescription,
    twitterImage: ogImage,
    twitterImageAlt: computed(() => settings.value?.seo?.title),
    twitterSite: `@${COMPANY_NAME}`,
  });
}
