<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value?: string | number;
    size?: "large" | "small";
  }>(),
  {
    value: "",
    size: "large",
  },
);

const discount = computed(() =>
  Number.isNaN(Number(props.value)) ? String(props.value || "%") : `${props.value}%`,
);

const textScale = computed(() => {
  const { length } = discount.value;
  if (length <= 3) {
    return 1;
  }

  const factor = (length - 3) / 9;
  return 1 - factor;
});

const svgProps = computed(() =>
  props.size === "small"
    ? {
        size: "49px",
        viewBox: "0 0 50 50",
        path: "M27.8398 2.06494C26.1857 0.719387 23.8144 0.719385 22.1603 2.06494L20.6624 3.28343C19.8887 3.91283 18.8833 4.18224 17.8985 4.02402L15.9921 3.71771C13.8868 3.37946 11.8332 4.56512 11.0735 6.55744L10.3856 8.36163C10.0302 9.29355 9.29416 10.0296 8.36224 10.3849L6.55805 11.0729C4.56574 11.8326 3.38007 13.8862 3.71832 15.9915L4.02463 17.8979C4.18285 18.8826 3.91344 19.8881 3.28405 20.6618L2.06555 22.1597C0.719997 23.8137 0.719995 26.1851 2.06555 27.8391L3.28404 29.337C3.91344 30.1107 4.18285 31.1162 4.02463 32.1009L3.71832 34.0074C3.38007 36.1126 4.56573 38.1662 6.55805 38.9259L8.36224 39.6139C9.29416 39.9692 10.0302 40.7053 10.3856 41.6372L11.0735 43.4414C11.8332 45.4337 13.8868 46.6194 15.9921 46.2811L17.8985 45.9748C18.8833 45.8166 19.8887 46.086 20.6624 46.7154L22.1603 47.9339C23.8144 49.2794 26.1857 49.2794 27.8398 47.9339L29.3376 46.7154C30.1113 46.086 31.1168 45.8166 32.1015 45.9748L34.008 46.2811C36.1132 46.6194 38.1669 45.4337 38.9265 43.4414L39.6145 41.6372C39.9698 40.7053 40.7059 39.9692 41.6378 39.6139L43.442 38.9259C45.4343 38.1662 46.62 36.1126 46.2817 34.0074L45.9754 32.1009C45.8172 31.1162 46.0866 30.1107 46.716 29.337L47.9345 27.8391C49.2801 26.1851 49.2801 23.8137 47.9345 22.1597L46.716 20.6618C46.0866 19.8881 45.8172 18.8826 45.9754 17.8979L46.2817 15.9915C46.62 13.8862 45.4343 11.8326 43.442 11.0729L41.6378 10.3849C40.7059 10.0296 39.9698 9.29355 39.6145 8.36163L38.9265 6.55744C38.1669 4.56513 36.1132 3.37946 34.008 3.71771L32.1015 4.02402C31.1168 4.18224 30.1113 3.91283 29.3376 3.28344L27.8398 2.06494Z",
      }
    : {
        size: "116px",
        viewBox: "0 0 116 116",
        path: "M55.4757 1.48113C56.946 0.285083 59.0538 0.285083 60.5241 1.48113L69.4956 8.77925C70.3798 9.49856 71.5289 9.80646 72.6543 9.62564L84.0729 7.79101C85.9442 7.49034 87.7697 8.54427 88.4449 10.3152L92.5654 21.1213C92.9715 22.1864 93.8127 23.0276 94.8778 23.4337L105.684 27.5541C107.455 28.2294 108.509 30.0548 108.208 31.9262L106.373 43.3447C106.193 44.4702 106.5 45.6193 107.22 46.5035L114.518 55.475C115.714 56.9452 115.714 59.0531 114.518 60.5234L107.22 69.4948C106.5 70.3791 106.193 71.5282 106.373 72.6536L108.208 84.0721C108.509 85.9435 107.455 87.7689 105.684 88.4442L94.8778 92.5646C93.8127 92.9708 92.9715 93.812 92.5654 94.877L88.4449 105.683C87.7697 107.454 85.9442 108.508 84.0729 108.207L72.6543 106.373C71.5289 106.192 70.3798 106.5 69.4956 107.219L60.5241 114.517C59.0538 115.713 56.946 115.713 55.4757 114.517L46.5042 107.219C45.62 106.5 44.4709 106.192 43.3455 106.373L31.9269 108.207C30.0556 108.508 28.2301 107.454 27.5549 105.683L23.4344 94.877C23.0283 93.812 22.1871 92.9708 21.122 92.5646L10.3159 88.4442C8.545 87.7689 7.49107 85.9435 7.79174 84.0722L9.62637 72.6536C9.80719 71.5282 9.49929 70.3791 8.77999 69.4948L1.48187 60.5234C0.285816 59.0531 0.285816 56.9452 1.48186 55.475L8.77999 46.5035C9.49929 45.6193 9.80719 44.4702 9.62637 43.3447L7.79174 31.9262C7.49107 30.0548 8.545 28.2294 10.3159 27.5541L21.122 23.4337C22.1871 23.0276 23.0283 22.1864 23.4344 21.1213L27.5549 10.3152C28.2301 8.54427 30.0556 7.49034 31.9269 7.79101L43.3455 9.62564C44.4709 9.80646 45.62 9.49856 46.5042 8.77926L55.4757 1.48113Z",
      },
);
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="svgProps.size"
    :viewBox="svgProps.viewBox"
    fill="none"
    data-icon
    :class="{
      'text-icon-sm': size === 'small',
      'text-icon-lg': size === 'large',
    }"
  >
    <path :d="svgProps.path" class="fill-[--background] stroke-[--stroke]" />
    <foreignObject height="100%" width="100%">
      <span
        data-text
        class="inline-flex size-full items-center justify-center font-display font-medium uppercase text-[--color]"
        v-text="discount"
      />
    </foreignObject>
  </svg>
</template>

<style scoped lang="postcss">
[data-icon] {
  min-width: v-bind("svgProps.size");

  path {
    stroke-width: var(--stroke-width, 2%);
  }
}

[data-text] {
  font-feature-settings:
    "liga" off,
    "clig" off;
  height: v-bind("svgProps.size");
  transform: scale(v-bind("textScale"));
}
</style>
