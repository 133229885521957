import type { Event } from "#shared/types/events";

function useQueryKey() {
  const version = useCrystallizeVersion();
  return ["/arrangementer", version] as const;
}

export function useEvents() {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    staleTime: 1000 * 60 * 60, // 1 hour
    placeholderData: [],
    async queryFn({ queryKey }) {
      const data = await GqlGetCatalogue({
        language: "no-nb",
        path: queryKey[0],
        version: queryKey[1],
      });

      return data.catalogue?.children?.map(toEvent) ?? [];
    },
    select(data) {
      return data.map(toEventCard).sort(byFirstDate("desc"));
    },
  });
}

export function useEvent(slug: MaybeRef<string>) {
  const queryKey = useQueryKey();

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [...queryKey, slug] as const,
    staleTime: 1000 * 60 * 10, // 10 minutes
    initialData() {
      const events = queryClient.getQueryData<Event[]>(queryKey);
      return events?.find((event) => event.slug.short === unref(slug));
    },
    initialDataUpdatedAt() {
      return queryClient.getQueryState(queryKey)?.dataUpdatedAt;
    },
    async queryFn({ queryKey }) {
      const data = await GqlGetPage({
        language: "no-nb",
        path: `${queryKey[0]}/${queryKey[2]}`,
        version: queryKey[1],
      });

      return data?.catalogue ? toEvent(data.catalogue) : null;
    },
  });
}
