<script setup lang="ts">
const props = defineProps<{
  background: boolean;
  textConversions?: Record<string, string | undefined>;
}>();

interface Crumb {
  id: string;
  title: string;
  link: string;
}

const route = useRoute();

const crumbs = computed(() => {
  const parts = route.path.split("/");
  const paths: string[] = [];
  const crumbs: Crumb[] = [];

  for (const part of parts) {
    if (part) {
      paths.push(part);
    }

    crumbs.push({
      id: String(crumbs.length + 1),
      title: part
        ? props.textConversions?.[part] || part.replace(/-/g, " ")
        : "Hjem",
      link: `/${paths.join("/")}`,
    });
  }

  return crumbs;
});
</script>

<template>
  <nav
    v-if="crumbs.length > 1"
    class="hidden w-screen justify-center p-4 xs:flex"
    :class="{
      'bg-green-50 text-white': background,
    }"
  >
    <ol class="flex w-content">
      <li
        v-for="crumb in crumbs"
        :key="crumb.id"
        class="text-[--text] [&+li]:before:px-[0.8rem] [&+li]:before:content-['/'] "
      >
        <NuxtLink :to="crumb.link" class="inline-block no-underline first-letter:capitalize hover:underline">
          {{ crumb.title }}
        </NuxtLink>
      </li>
    </ol>
  </nav>
</template>
