<script setup lang="ts">
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const previewMode = usePreviewMode();

const script = previewMode.enabled.value
  ? [
      {
        src: "https://app.crystallize.com/static/frontend-preview-listener.js",
        defer: true,
      },
    ]
  : [];

const cart = useCartStore();

const route = useRoute();

function refreshCart() {
  return cart.hydrateTempItems(route);
}

onMounted(refreshCart);

useEventListener(window, "focus", refreshCart);

useHead({
  htmlAttrs: {
    lang: "no",
    prefix: "og: https://ogp.me/ns#",
    class: "font-body bg-green-50",
  },
  bodyAttrs: {
    class: "bg-beige-10",
  },
  script,
});

await useFallbackSeo();
</script>

<template>
  <NuxtRouteAnnouncer />
  <NuxtLoadingIndicator />
  <LayoutSkipTo />
  <div class="grid min-h-screen grid-rows-[auto_1fr_auto] [&>*]:overflow-x-hidden">
    <LayoutHeader />
    <NuxtPage />
    <LayoutFooter />
  </div>
  <VueQueryDevtools />
</template>
