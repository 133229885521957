<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { PRODUCT_CATALOGUE_ROUTE } from "~/pages/produktkatalog/index.vue";
import { SEARCH_ROUTE } from "~/pages/sok.vue";

const closeBtn: Ref<HTMLButtonElement | undefined> = ref();
const termValue: Ref<string | undefined> = ref();

function reFocus() {
  closeBtn.value?.focus();
}

const open = defineModel("open", {
  default: false,
});

const router = useRouter();

async function handleSearchInput() {
  if (termValue.value) {
    await router.push({
      name: SEARCH_ROUTE,
      query: { q: termValue.value },
    });
  }
}
</script>

<template>
  <div class="fixed left-0 top-0 size-full bg-[#D15C5C]/80" aria-hidden="true" />
  <div data-wrapper class="fixed right-0 top-0 h-full w-screen overflow-y-auto bg-green-50 tablet:w-[50dvw]">
    <div class="flex h-24 w-full justify-end border-b border-green-30 px-6 tablet:px-0">
      <button
        id="close-button"
        ref="closeBtn"
        aria-label="Lukk sidemeny"
        @click="open = false"
      >
        <KsIcon :icon="faXmark" class="!size-[2.125rem] text-green-30 xs:!size-12" />
      </button>
    </div>
    <div data-content class="z-999 mx-auto flex min-h-fit max-w-80 flex-col p-8 xs:max-w-[30rem] tablet:mx-0 tablet:py-16 tablet:pl-20">
      <div class="flex flex-col border-b border-green-30">
        <label for="searchfield" class="text-base-versal text-green-30 xs:text-lg-versal">SØK</label>
        <UiSearchField v-model="termValue" @submit.prevent="handleSearchInput" />
      </div>
      <nav>
        <ul class="[&>li]:border-b [&>li]:border-green-30">
          <UiSidebarLink :to="{ name: PRODUCT_CATALOGUE_ROUTE }">
            <IconsToolbarLaptop />
            Produktkatalog
          </UiSidebarLink>
          <UiSidebarLink to="/arrangementer">
            <IconsToolbarEvents />
            Arrangementer
          </UiSidebarLink>
          <UiSidebarLink to="/artikler">
            <IconsToolbarArticles />
            Artikler
          </UiSidebarLink>
          <UiSidebarLink to="/podkaster">
            <IconsToolbarPodcasts />
            Podkaster
          </UiSidebarLink>
          <UiSidebarLink to="/kundeservice">
            <IconsToolbarCustomerService />
            Kundeservice
          </UiSidebarLink>
          <UiSidebarLink
            to="/om-aschehoug"
            @blur.prevent="reFocus"
          >
            Om Aschehoug
          </UiSidebarLink>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped>
[data-wrapper] {
  animation: slide-in 0.3s ease-in-out;

  > div {
    @media screen and (min-width: theme("screens.tablet")) {
      max-width: calc(theme("spacing.content") / 2);
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
