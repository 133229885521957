
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as sokwIhhxiJL_faX89SxpzOAhnInphObrQoKqTntSpmg6asMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/sok.vue?macro=true";
import { default as takkL_454TJ97_45I3vOKqG1DXiwdGuLAvU7ifDy2LkkZ4vDtOgMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/takk.vue?macro=true";
import { default as indexb5vEHX6Klu1XI2VbqAkKCThlJO_45t4u9knmZoWcNszL8Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/index.vue?macro=true";
import { default as minsidenKgM2P90FXNLoR5C66f_45P43rPJOo0OuqH498sVvPqpkMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/minside.vue?macro=true";
import { default as _91_46_46_46slug_93MSjDSWC3iY_cdCNoNufWGZ6rrPqlqNjYhLk_Xr9AzaoMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/[...slug].vue?macro=true";
import { default as handlekurvo4kRhBIGIwMXLnjspVac00QIKjLGwKTXlz_xYSjThp0Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/handlekurv.vue?macro=true";
import { default as nyhetsbrevo4oRV12kBZTH6GwnUuczT3_45LUKgYe1HT45LTl7XL2tgMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/nyhetsbrev.vue?macro=true";
import { default as personvernKgPpIZyRDqnhPWWvIwHXfWPqTWpGupgjgKVUPMDpdrQMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/personvern.vue?macro=true";
import { default as indexZ1is43RDN8JJ0Yfm6c0IkWdHUtBXlE7Nj5mDAFzxZtgMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/index.vue?macro=true";
import { default as kundeservice9gy1UHVyFLZ5YzgKd4KXCs_45RgtELCZGbj3Yc8k2GNIoMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/kundeservice.vue?macro=true";
import { default as om_45aschehougtWbFuydF9LqttDYqXwSGNqFylbglGjMpHQB1wOAqn5gMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/om-aschehoug.vue?macro=true";
import { default as bekreftHkkDBoWUZimTSVagwcHeD2fGa7_45PDe7gkX8ZnjJ6a60Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/bekreft.vue?macro=true";
import { default as indexViDW_asXJ3sBMLpE2kwctaYUL7noB81gcsZjcD3leaIMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/artikler/index.vue?macro=true";
import { default as _91slug_93f1aDc4rIDCRl1D42vYhgQqtKDYYZBwu3WQPfNxZQSagMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/artikler/[slug].vue?macro=true";
import { default as indext72_45R5nv0_aFUgL0KNPl5e1LJJO96SAc76CmQBgPdbIMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/podkaster/index.vue?macro=true";
import { default as kjopsbetingelsernbeQuWYua8hR4p_45KihZdaErVeKpKjrrh7lleYWKDZlMMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/kjopsbetingelser.vue?macro=true";
import { default as _91slug_93aG_cCaPpC8TxnTsfjcSFnOVkm6p8_hUZ8E3znYLEf1oMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/podkaster/[slug].vue?macro=true";
import { default as brukerinvolveringKKqolElJ92PiIOZRL4W8coK8AsFYtfS85ikeuZ172rwMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/brukerinvolvering.vue?macro=true";
import { default as indexwkPQRc3S1c07cR7wlo8fOE_45_HyBP_TSXGwrDnsliMOkMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/arrangementer/index.vue?macro=true";
import { default as informasjonskapslerIv_45M_5bhRc3hcyLPxUQ2DT7yd88jZ_456zQ0JdR8neYHYMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/informasjonskapsler.vue?macro=true";
import { default as indextgcRtYkVScM03RRhqhPNpV0b6zkyhRmZxaQ1caaJ6WkMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/produktkatalog/index.vue?macro=true";
import { default as _91event_93eexHRt_456KuytzDCYHtmF_xukrIJOHQNvzznQXEFN9UUMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/arrangementer/[event].vue?macro=true";
import { default as callback_46clientF4YEgFOiWZmmc0KWbtyPJPpvW_45IZzBUNH8SWpjSV0uQMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/callback.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/aschehoug/aschehoug-utdanning/shop-app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.20.1_jiti@2.4.2__iore_avszajnybbnynxbc5jrzk4g5oa/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91slug_937VdGvsurd0oI8Hp_456aS9hMM05OhmhKybZqw3mMNGrn8Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/produktkatalog/[slug].vue?macro=true";
export default [
  {
    name: sokwIhhxiJL_faX89SxpzOAhnInphObrQoKqTntSpmg6asMeta?.name ?? "sok",
    path: "/sok",
    meta: sokwIhhxiJL_faX89SxpzOAhnInphObrQoKqTntSpmg6asMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/sok.vue")
  },
  {
    name: "takk",
    path: "/takk",
    meta: takkL_454TJ97_45I3vOKqG1DXiwdGuLAvU7ifDy2LkkZ4vDtOgMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/takk.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/index.vue")
  },
  {
    name: "minside",
    path: "/minside",
    meta: minsidenKgM2P90FXNLoR5C66f_45P43rPJOo0OuqH498sVvPqpkMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/minside.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/[...slug].vue")
  },
  {
    name: "handlekurv",
    path: "/handlekurv",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/handlekurv.vue")
  },
  {
    name: "nyhetsbrev",
    path: "/nyhetsbrev",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/nyhetsbrev.vue")
  },
  {
    name: "personvern",
    path: "/personvern",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/personvern.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexZ1is43RDN8JJ0Yfm6c0IkWdHUtBXlE7Nj5mDAFzxZtgMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/index.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/kundeservice.vue")
  },
  {
    name: "om-aschehoug",
    path: "/om-aschehoug",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/om-aschehoug.vue")
  },
  {
    name: "login-bekreft",
    path: "/login/bekreft",
    meta: bekreftHkkDBoWUZimTSVagwcHeD2fGa7_45PDe7gkX8ZnjJ6a60Meta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/bekreft.vue")
  },
  {
    name: "artikler",
    path: "/artikler",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/artikler/index.vue")
  },
  {
    name: "artikler-slug",
    path: "/artikler/:slug()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/artikler/[slug].vue")
  },
  {
    name: "podkaster",
    path: "/podkaster",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/podkaster/index.vue")
  },
  {
    name: "kjopsbetingelser",
    path: "/kjopsbetingelser",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/kjopsbetingelser.vue")
  },
  {
    name: "podkaster-slug",
    path: "/podkaster/:slug()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/podkaster/[slug].vue")
  },
  {
    name: "brukerinvolvering",
    path: "/brukerinvolvering",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/brukerinvolvering.vue")
  },
  {
    name: "arrangementer",
    path: "/arrangementer",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/arrangementer/index.vue")
  },
  {
    name: "informasjonskapsler",
    path: "/informasjonskapsler",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/informasjonskapsler.vue")
  },
  {
    name: indextgcRtYkVScM03RRhqhPNpV0b6zkyhRmZxaQ1caaJ6WkMeta?.name ?? "produktkatalog",
    path: "/produktkatalog",
    meta: indextgcRtYkVScM03RRhqhPNpV0b6zkyhRmZxaQ1caaJ6WkMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/produktkatalog/index.vue")
  },
  {
    name: "arrangementer-event",
    path: "/arrangementer/:event()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/arrangementer/[event].vue")
  },
  {
    name: "login-callback",
    path: "/login/callback",
    component: () => createClientPage(() => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/login/callback.client.vue"))
  },
  {
    name: _91slug_937VdGvsurd0oI8Hp_456aS9hMM05OhmhKybZqw3mMNGrn8Meta?.name ?? "produktkatalog-slug",
    path: "/produktkatalog/:slug()",
    meta: _91slug_937VdGvsurd0oI8Hp_456aS9hMM05OhmhKybZqw3mMNGrn8Meta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/app/pages/produktkatalog/[slug].vue")
  }
]