import type { Document } from "#shared/types/document";
import type { ItemRelation, Page } from "#shared/utils/crystallize";

export function createDocument<T extends Document>(
  item: ItemRelation | Page,
  partialT: Omit<T, keyof Document>,
): T {
  const document: Document = {
    id: item.id,
    title: "",
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    publishedAt: item.publishedAt,
    seo: {},
    levels: [],
    subject: [],
    theme: [],
    slug: pathToSlug(item.path ?? ""),
    shape: {
      identifier: item.shape?.identifier ?? "",
      name: item.shape?.name ?? null,
    },
  };

  return {
    ...document,
    ...partialT,
  } as T;
}
