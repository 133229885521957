import type { Subject } from "#shared/types/core/common";

// Don't change the order of this, as it is used for sorting single product variants.
export const GRADES = [
  "aarstrinn1",
  "aarstrinn2",
  "aarstrinn3",
  "aarstrinn4",
  "aarstrinn5",
  "aarstrinn6",
  "aarstrinn7",
  "aarstrinn8",
  "aarstrinn9",
  "aarstrinn10",
  "vg1",
  "vg2",
  "vg3",
  "vok",
  "prep",
  "forkurs",
  "a1/a2",
  "b1",
  "b2",
  "b3",
] as const;

export type GradeCode = (typeof GRADES)[number];

// Don't change the order of this, as it is used for sorting products in the product catalogue.
export const LEVELS = [
  "1-7",
  "8-10",
  "vgs",
  "voksenopplaering",
  "student",
] as const;

export type Level = (typeof LEVELS)[number];

export const LEVEL_FILTERS = ["alle", "ungdomsskole", "vgs"];

export type LevelFilter = (typeof LEVEL_FILTERS)[number];

export const GRADE_TO_LEVEL: Record<GradeCode, Level> = {
  "aarstrinn1": "1-7",
  "aarstrinn2": "1-7",
  "aarstrinn3": "1-7",
  "aarstrinn4": "1-7",
  "aarstrinn5": "1-7",
  "aarstrinn6": "1-7",
  "aarstrinn7": "1-7",
  "aarstrinn8": "8-10",
  "aarstrinn9": "8-10",
  "aarstrinn10": "8-10",
  "vg1": "vgs",
  "vg2": "vgs",
  "vg3": "vgs",
  "vok": "voksenopplaering",
  "a1/a2": "voksenopplaering",
  "b1": "voksenopplaering",
  "b2": "voksenopplaering",
  "b3": "voksenopplaering",
  "prep": "student",
  "forkurs": "student",
};

// Don't change the order of this, as it is used for sorting single product variants.
export const BIBLIO_FORMATS = [
  "lærebok",
  "lesehefte",
  "digitalbok",
  "arbeidsbok",
  "lærerveiledning",
  "lærernettsted",
  "nettsted",
  "tilleggsmateriell",
] as const;

export type BiblioFormat = (typeof BIBLIO_FORMATS)[number];

export const PRODUCT_FORMATS = [
  "arbeidsbok",
  "grunnbok",
  "unibok",
  "nettsted",
  "lesebok",
  "laererprodukt",
] as const;

export const FORMAT_MAPPING = {
  arbeidsbok: "arbeidsbok",
  digitalbok: "unibok",
  lesehefte: "lesebok",
  lærebok: "grunnbok",
  lærernettsted: "nettsted",
  nettsted: "nettsted",
  lærerveiledning: "laererprodukt",
  tilleggsmateriell: "laererprodukt",
} as const satisfies Record<BiblioFormat, ProductFormat>;

export type ProductFormat = (typeof PRODUCT_FORMATS)[number];

export interface Option<T extends string = string> {
  value: T;
  title: string;
  tagName: string;
}

export type Options<
  T extends string,
  Extra extends Record<string, unknown> = Record<string, unknown>,
> = ReadonlyArray<Option<T> & Extra>;

export const PRODUCT_FORMAT_OPTIONS = [
  {
    value: "arbeidsbok",
    title: "Arbeidsbok",
    tagName: "Arbeidsbok",
  },
  {
    value: "grunnbok",
    title: "Grunnbok",
    tagName: "Grunnbok",
  },
  {
    value: "unibok",
    title: "Unibok",
    tagName: "Unibok",
  },
  {
    value: "nettsted",
    title: "Nettsted",
    tagName: "Nettsted",
  },
  {
    value: "lesebok",
    title: "Lesebok",
    tagName: "Lesebok",
  },
  {
    value: "laererprodukt",
    title: "Lærerprodukt",
    tagName: "Lærerprodukt",
  },
] as const satisfies Options<ProductFormat>;

export type ProductFormatOption = (typeof PRODUCT_FORMAT_OPTIONS)[number];

export const LEVEL_OPTIONS = [
  {
    value: "1-7",
    title: "Barneskole",
    tagName: "1-7",
  },
  {
    value: "8-10",
    title: "Ungdomsskole",
    tagName: "8-10",
  },
  {
    value: "vgs",
    title: "Videregående skole",
    tagName: "VGS",
  },
  {
    value: "voksenopplaering",
    title: "Voksenopplæring",
    tagName: "Voksen",
  },
  {
    value: "student",
    title: "Student",
    tagName: "Student",
  },
] as const satisfies Options<Level>;

export type LevelOption = (typeof LEVEL_OPTIONS)[number];

export const SUBJECTS = [
  "norwegian",
  "science",
  "languages",
  "social-studies",
  "multiple",
  "norwegian-foreigners",
  "other",
] as const;

export type SubjectCategory = (typeof SUBJECTS)[number];

// TODO: This is used both for product filter and article filter, they should be separated.
export const SUBJECT_OPTIONS = [
  {
    value: "norwegian",
    title: "Norsk",
    tagName: "Norsk",
  },
  {
    value: "science",
    title: "Realfag",
    tagName: "Realfag",
  },
  {
    value: "languages",
    title: "Språkfag",
    tagName: "Språkfag",
  },
  {
    value: "social-studies",
    title: "Samfunnsfag",
    tagName: "Samfunnsfag",
  },
  {
    value: "multiple",
    title: "Tverrfaglig",
    tagName: "Tverrfaglig",
  },
  {
    value: "norwegian-foreigners",
    title: "Norsk som andrespråk",
    tagName: "Norsk som andrespråk",
  },
  {
    value: "other",
    title: "Annet",
    tagName: "Annet",
  },
] as const satisfies Options<SubjectCategory>;

export type SubjectOption = (typeof SUBJECT_OPTIONS)[number];

export const THEMES = [
  "health-and-life",
  "democracy",
  "sustainability",
  "reading",
  "tips",
  "podcast",
] as const;

export type Theme = (typeof THEMES)[number];

export const THEME_OPTIONS = [
  {
    value: "health-and-life",
    title: "Folkehelse og livsmestring",
    tagName: "Folkehelse og livsmestring",
  },
  {
    value: "democracy",
    title: "Demokrati og medborgerskap",
    tagName: "Demokrati og medborgerskap",
  },
  {
    value: "sustainability",
    title: "Bærekraftig utvikling",
    tagName: "Bærekraftig utvikling",
  },
  {
    value: "reading",
    title: "Lesing",
    tagName: "Lesing",
  },
  {
    value: "tips",
    title: "Tips & triks",
    tagName: "Tips & triks",
  },
  {
    value: "podcast",
    title: "Podkast",
    tagName: "Podkast",
  },
] as const satisfies Options<Theme>;

export const VGS_GRADE_OPTIONS = [
  {
    value: "vg1",
    title: "Vg1",
    tagName: "Vg1",
  },
  {
    value: "vg2",
    title: "Vg2",
    tagName: "Vg2",
  },
  {
    value: "vg3",
    title: "Vg3",
    tagName: "Vg3",
  },
] as const satisfies Options<GradeCode>;

export const GRUNNSKOLE_GRADE_OPTIONS = [
  {
    value: "1-4",
    title: "1-4",
    tagName: "1-4",
    grades: ["aarstrinn1", "aarstrinn2", "aarstrinn3", "aarstrinn4"],
  },
  {
    value: "5-7",
    title: "5-7",
    tagName: "5-7",
    grades: ["aarstrinn5", "aarstrinn6", "aarstrinn7"],
  },
  {
    value: "8-10",
    title: "8-10",
    tagName: "8-10",
    grades: ["aarstrinn8", "aarstrinn9", "aarstrinn10"],
  },
] as const satisfies Options<string, { grades: GradeCode[] }>;

export const UNGDOMSSKOLE_GRADE_OPTIONS = [
  {
    value: "aarstrinn8",
    title: "8",
    tagName: "8",
  },
  {
    value: "aarstrinn9",
    title: "9",
    tagName: "9",
  },
  {
    value: "aarstrinn10",
    title: "10",
    tagName: "10",
  },
] as const satisfies Options<GradeCode>;

export const ALL_GRADE_OPTIONS = [
  {
    value: "1-7",
    title: "1-7",
    tagName: "1-7",
    grades: [
      "aarstrinn1",
      "aarstrinn2",
      "aarstrinn3",
      "aarstrinn4",
      "aarstrinn5",
      "aarstrinn6",
      "aarstrinn7",
    ],
  },
  {
    value: "8-10",
    title: "8-10",
    tagName: "8-10",
    grades: ["aarstrinn8", "aarstrinn9", "aarstrinn10"],
  },
  {
    value: "vgs",
    title: "VGS",
    tagName: "VGS",
    grades: ["vg1", "vg2", "vg3"],
  },
  {
    value: "voksenopplaering",
    title: "Voksen",
    tagName: "Voksen",
    grades: ["vok", "a1/a2", "b1", "b2", "b3"],
  },
  {
    value: "student",
    title: "Student",
    tagName: "Student",
    grades: ["prep", "forkurs"],
  },
] as const satisfies Options<string, { grades: GradeCode[] }>;

export const SUBJECT_LABELS: Record<
  Subject,
  string | [name: string, biblioName: string]
> = {
  ENG: "Engelsk",
  FSP: "Fremmedspråk",
  KRO: "Kroppsøving",
  MAT: "Matematikk",
  NAT: "Naturfag",
  NOR: "Norsk",
  SAF: "Samfunnsfag",
  FYS: "Fysikk",
  GEO: "Geografi",
  GFG: "Geofag",
  HEL: ["Helse", "Helse- og oppvekstfag"],
  HIS: "Historie",
  KJE: "Kjemi",
  MED: "Mediefag",
  POL: ["Politikk", "Politikk, individ og samfunn"],
  PSY: "Psykologi",
  REL: ["Religion", "Religion og etikk"],
  KRL: "KRLE",
  TVE: "Tverrfaglig",
  INF: "Informasjonsteknologi",
  SPA: "Spansk",
  TYS: "Tysk",
  FRA: "Fransk",
  KHV: "Kunst og håndverk",
  MUS: "Musikk",
  MHE: "Mat og helse",
  SAK: "Samfunnskunnskap",
  SOK: "Sosialkunnskap",
  SAG: "Samfunnsgeografi",
  SOS: "Sosiologi og sosialantropologi",
  POM: "Politikk og menneskerettigheter",
  PED: "Pedagogisk litteratur",
};

export const PRODUCT_FORMS = ["DIGITAL", "PAPER"] as const;

export type ProductForm = (typeof PRODUCT_FORMS)[number];
