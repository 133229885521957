export function getSubjectTitleFromValue(
  key: string | undefined,
  defaultValue = "Alle fag",
): SubjectOption["title"] | string {
  if (!key) {
    return defaultValue;
  }

  return SUBJECT_OPTIONS.find((it) => it.value === key)?.title ?? defaultValue;
}
