<script setup lang="ts">
const jumpToContent = ref();

function unFocusEL() {
  jumpToContent.value?.blur();
}

onMounted(() => {
  jumpToContent.value = document.getElementById("skip-to-content-link")!;
});
</script>

<template>
  <NuxtLink
    id="skip-to-content-link"
    to="#main-content"
    class="absolute -top-96 z-[1000] flex min-h-24 w-screen items-center justify-center bg-green-30 p-4 text-center text-xl text-white focus:top-0"
    @keyup.enter.prevent="unFocusEL"
    @keyup.space.prevent="unFocusEL"
    @click.prevent="unFocusEL"
  >
    Hopp til hovedinnhold
  </NuxtLink>
</template>
