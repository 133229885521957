<script setup lang="ts">
import type { NuxtLinkProps } from "#app";
import type { Crystallize } from "#shared/utils/crystallize-spec";

const props = defineProps<{
  product: ProductCard<Packet | SingleProduct>;
  tag?: string;
  subtitle?: string;
  description?: string;
}>();

const route = useRoute();

const description = computed(() => props.description || getGradeRange(props.product));

const url = computed<NuxtLinkProps["to"]>(() => ({
  path: `/produktkatalog/${props.product.slug.short}`,
  query: route.query,
}));

const title = computed(() => {
  const preference = [props.product.title, props.product.name];

  if (isPacket(props.product)) {
    // FIXME: Packets currently have a bit weird titles, so for them we want name to take precedence over title. We should fix that.
    preference.reverse();
  }

  return preference.find(truthy);
});

const CLASS_MAP = {
  "productcard-green": "bg-green-45",
  "productcard-seagreen": "bg-green-30",
  "productcard-blue": "bg-blue-50",
  "productcard-darkblue": "bg-blue-60",
  "productcard-red": "bg-red-45",
  "productcard-darkred": "bg-red-60",
  "productcard-purple": "bg-purple",
} as const satisfies Record<Crystallize.SingleProductCardColor, string>;
</script>

<template>
  <LinkCard
    :to="url"
    :class="[product.background && CLASS_MAP[product.background]]"
    :tag="tag"
    :image="product.cover"
    :title="title ?? ''"
    :subtitle="subtitle"
    :description="description"
  >
    <template #icon>
      <slot name="icon" />
    </template>
  </LinkCard>
</template>
