<script lang="ts">
import type { Campaign } from "#shared/utils/campaign";

export const PRODUCT_CATALOGUE_ROUTE = "produktkatalog";
</script>

<script setup lang="ts">
definePageMeta({
  name: PRODUCT_CATALOGUE_ROUTE,
});

const version = useCrystallizeVersion();

const { time } = useServerTiming();

const { data: campaign } = useQuery<Campaign | null>({
  queryKey: ["campaign", "aunivers", version],
  staleTime: 1000 * 60 * 60,
  queryFn: async () => {
    const data = await time("GetAuniversCampaign", () =>
      GqlGetPage({
        language: "no-nb",
        path: "/kampanjer/aunivers",
        version: useCrystallizeVersion(),
      }));

    return toCampaign(data.catalogue);
  },
});

const { data: productCatalogue, suspense } = useProducts();

await suspense();

const products = computed(() => {
  const all = productCatalogue.value?.map(toProductCard) ?? [];

  return [...all].sort((a, b) => {
    if (a.type === b.type) {
      return 0;
    }

    if (isPacket(a)) {
      return -1;
    }

    return 1;
  });
});

const route = useRoute();

const filtered = computed(() => {
  const filters = route.query;

  return products.value
    .filter(byLevels(filters))
    .filter(bySubjects(filters))
    .filter(byFormats(filters))
    .sort(byTypeAndLevel);
});

useHead({
  bodyAttrs: {
    class: "bg-green-10",
  },
});
</script>

<template>
  <div>
    <LayoutBreadCrumbs :background="true" />
    <main
      id="main-content"
      class="m-auto mb-20 flex w-full flex-col justify-center self-center"
      aria-labelledby="page-title"
    >
      <h1 id="page-title" class="headline mb-3 mt-16 text-center text-3xl font-normal text-green-50 xs:mb-4 xs:text-left xs:text-5xl tablet:mb-6 tablet:text-6xl">
        Finn læremidler
      </h1>
      <UiProductsCatalogFilter :products="products" />
      <UiCampaignCallout class="mx-auto mb-8" :campaign="campaign" />
      <UiProductsCatalog :products="filtered" />
    </main>
  </div>
</template>

<style scoped>
#main-content {
  max-width: min(95%, theme("spacing.content"));
}
</style>
