<template>
  <svg
    width="50"
    height="24"
    viewBox="0 0 50 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 11.6667C50 11.6667 38.8071 23.3333 25 23.3333C11.1929 23.3333 0 11.6667 0 11.6667C0 11.6667 11.1929 0 25 0C38.8071 0 50 11.6667 50 11.6667Z"
      class="fill-seagreen-20"
    />
    <circle
      cx="24.5"
      cy="11.5"
      r="7.5"
      class="fill-green-50"
    />
  </svg>
</template>
