<script setup lang="ts" generic="S extends string">
import { KsButton, KsInput } from "@aschehoug/kloss";

const props = defineProps<{
  legend?: string;
  name: string;
  options: Options<S>;
  enabled?: Set<S>;
}>();

const route = useRoute();

function isChecked(value: string) {
  const queryValue = route.query[props.name];
  return (
    queryValue === value ||
    (Array.isArray(queryValue) && queryValue.includes(value))
  );
}
</script>

<template>
  <fieldset>
    <legend v-if="legend" v-text="legend" />
    <KsButton
      v-for="option in options"
      :key="option.value"
      variant="secondary"
      type="label"
      class="checkbox"
      :disabled="enabled && !enabled.has(option.value)"
    >
      <KsInput
        type="checkbox"
        :name="name"
        :value="option.value"
        :checked="isChecked(option.value)"
        :disabled="enabled && !enabled.has(option.value)"
        @change="handleFilterChange"
      />
      {{ option.tagName }}
    </KsButton>
  </fieldset>
</template>

<style scoped lang="scss">
.checkbox {
  --ks-primary: var(--foreground, theme("colors.seagreen.50"));
  --ks-secondary: var(--background);

  color: var(--ks-primary);
  font-weight: normal;
  background: transparent;
  padding: 0;
  display: flex;
  height: fit-content;
  align-items: center;
}

fieldset {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  border: none;
  color: var(--foreground, theme("colors.seagreen.50"));

  @media screen and (min-width: $small) {
    gap: 1rem;
  }
}

legend {
  margin-bottom: 2.75rem;
  font-weight: 600;
}
</style>
