<template>
  <svg
    width="55"
    height="42"
    viewBox="0 0 55 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 27015">
      <g id="Group 27013">
        <path
          id="Rectangle 37563"
          d="M29.9384 1.10689C30.0227 0.792252 30.3743 0.633985 30.6657 0.779506L41.385 6.13205L36.7073 23.5897L25.7999 18.1433C25.581 18.034 25.4693 17.7857 25.5326 17.5494L29.9384 1.10689Z"
          fill="#6EAEB7"
        />
        <path
          id="Rectangle 37564"
          d="M53.8055 7.50338C53.8899 7.18874 53.6645 6.87586 53.3393 6.85617L41.3799 6.13195L36.7021 23.5896L48.8713 24.3266C49.1156 24.3413 49.3365 24.1822 49.3998 23.9459L53.8055 7.50338Z"
          fill="#6EAEB7"
        />
        <path
          id="Rectangle 37561"
          d="M32.0597 1.15605C32.1452 0.837042 32.5047 0.679859 32.7969 0.833705L41.5696 5.45195L37.2427 21.6003L28.2636 16.8734C28.0507 16.7613 27.9438 16.5169 28.0061 16.2845L32.0597 1.15605Z"
          fill="#E5FDFF"
        />
        <path
          id="Rectangle 37562"
          d="M51.9425 6.48637C52.028 6.16736 51.7953 5.85151 51.4653 5.83862L41.5588 5.45179L37.2319 21.6001L47.3715 21.9961C47.6118 22.0055 47.8266 21.8472 47.8889 21.6148L51.9425 6.48637Z"
          fill="#E5FDFF"
        />
        <g id="Group 27014">
          <path
            id="Rectangle 37565"
            d="M33.4062 4.05054L38.9562 7.21022L38.708 8.13639L33.1581 4.97671L33.4062 4.05054Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37566"
            d="M32.875 6.04004L38.4249 9.19972L38.1768 10.1259L32.6268 6.96621L32.875 6.04004Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37567"
            d="M32.3438 8.0293L37.8937 11.189L37.6455 12.1151L32.0956 8.95547L32.3438 8.0293Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37568"
            d="M31.8125 10.0188L37.3624 13.1785L37.1143 14.1046L31.5643 10.945L31.8125 10.0188Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37569"
            d="M31.2734 12.0081L36.8234 15.1677L36.5752 16.0939L31.0253 12.9342L31.2734 12.0081Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37570"
            d="M30.7422 13.9973L36.2921 17.157L36.044 18.0832L30.494 14.9235L30.7422 13.9973Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37571"
            d="M48.8281 8.18188L42.4419 8.14327L42.1937 9.06944L48.58 9.10806L48.8281 8.18188Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37572"
            d="M48.2969 10.1711L41.9106 10.1325L41.6625 11.0587L48.0487 11.0973L48.2969 10.1711Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37573"
            d="M47.7578 12.1606L41.3716 12.122L41.1234 13.0482L47.5096 13.0868L47.7578 12.1606Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37574"
            d="M47.2266 14.1499L40.8403 14.1113L40.5922 15.0375L46.9784 15.0761L47.2266 14.1499Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37575"
            d="M46.6953 16.1392L40.3091 16.1005L40.0609 17.0267L46.4471 17.0653L46.6953 16.1392Z"
            fill="#84D2D8"
          />
          <path
            id="Rectangle 37576"
            d="M46.1641 18.1287L39.7778 18.0901L39.5297 19.0162L45.9159 19.0548L46.1641 18.1287Z"
            fill="#84D2D8"
          />
        </g>
      </g>
      <g id="Group 27016">
        <path
          id="Rectangle 37581"
          d="M3.75781 13.2632C3.75781 12.6528 4.25266 12.158 4.86308 12.158H33.5999C34.2103 12.158 34.7052 12.6528 34.7052 13.2632V34.2632H3.75781V13.2632Z"
          fill="#6EAEB7"
        />
        <path
          id="Rectangle 37582"
          d="M1.10559 40.4594C0.793021 41.1887 1.328 42 2.12149 42H36.3481C37.1416 42 37.6766 41.1887 37.364 40.4594L34.7085 34.2632H3.7611L1.10559 40.4594Z"
          fill="#6EAEB7"
        />
        <path
          id="Rectangle 37584"
          d="M4.73936 38.3667C4.52661 39.0758 5.05763 39.7896 5.79801 39.7896H33.0857C33.8647 39.7896 34.3992 39.0053 34.1145 38.2803L32.5369 34.2632H5.97039L4.73936 38.3667Z"
          fill="#84D2D8"
        />
        <rect
          id="Rectangle 37583"
          x="5.96875"
          y="14.3684"
          width="26.5263"
          height="17.6842"
          rx="1.10526"
          fill="#E5FDFF"
        />
      </g>
    </g>
  </svg>
</template>
