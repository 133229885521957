<script lang="ts">
export const SEARCH_ROUTE = "search";
</script>

<script setup lang="ts">
definePageMeta({
  name: SEARCH_ROUTE,
});

const articles = useArticleContent();
const productVariants = useProductVariants();
const events = useEvents();
const podcasts = usePodcasts();

await Promise.allSettled([
  events.suspense(),
  articles.suspense(),
  productVariants.suspense(),
  podcasts.suspense(),
]);

const route = useRoute();
const term = computed(() => route.query.q?.toString());
const router = useRouter();

const termValue = ref(route.query.q?.toString());

watch(term, (value) => {
  termValue.value = value;
});

function cleanSearch() {
  termValue.value = "";
}

function handleSearchInput() {
  return router.push({
    query: { q: termValue.value },
  });
}

enum SearchCategory {
  Product = "product",
  Article = "article",
  Event = "event",
  Podcast = "podcast",
}

interface ResultItem {
  id: string;
  tags: string[];
  title: string;
  text: string;
  link: string;
  type: SearchCategory;
  image?: ImageWithAltType;
}

interface SearchResult {
  products: ResultItem[];
  articles: ResultItem[];
  events: ResultItem[];
  podcasts: ResultItem[];
}

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string | string[] | null | undefined ? K : never;
}[keyof T];

function getGradeTag(levels: Level[]): string {
  if ((["1-7", "8-10"] as const).every((level) => levels.includes(level))) {
    return levels.includes("vgs") ? "Alle trinn" : "1-10";
  }

  if (
    (["1-7", "8-10", "vgs"] as const).some((level) => levels.includes(level))
  ) {
    return levels.join(", ");
  }

  return "";
}

const results = computed<SearchResult>(() => {
  const termValue = term.value?.toLowerCase()?.split(" ");

  function search<T>(...keys: StringKeys<NonNullable<T>>[]) {
    return (item: T): item is NonNullable<T> => {
      return (
        !!termValue?.length &&
        keys.some((key) => {
          const value = item?.[key];
          return termValue.every((tv) =>
            value?.toString().toLowerCase().includes(tv),
          );
        })
      );
    };
  }

  return {
    products: productVariants.variants.value
      ?.filter(search("title", "parentTitle", "description", "isbn", "name"))
      .map((variant) => ({
        id: variant.sku,
        tags: [getGradeRange(variant), "Læremidler"].filter(truthy),
        title: (
          isPacketVariant(variant)
            ? (variant.customName || variant.title || variant.name)
            : (variant.title || variant.name)
        ) || "",
        text: variant.description ?? "",
        image: variant.cover,
        type: SearchCategory.Product,
        link: isPacketVariant(variant)
          ? `/produktkatalog/${variant.parentSlug.short}`
          : `/produktkatalog/${variant.parentSlug.short}#${variant.sku}`,
      })),

    articles:
      articles.data.value
        ?.filter(search("body", "title", "intro"))
        .map((filtered) => {
          const grade = getGradeTag(filtered.levels);
          const subject = getSubjectTitleFromValue(
            filtered.subject?.join("") ?? "",
          );
          return {
            id: filtered.id,
            tags: [grade, "Artikkel", subject].filter(truthy),
            title: filtered.title,
            text: filtered.intro ?? "",
            image: filtered.cover,
            type: SearchCategory.Article,
            link: filtered.slug.full,
          };
        }) ?? [],

    events:
      events.status.value === "error"
        ? []
        : (events.data.value
            ?.filter(search("title", "description"))
            .map((filtered) => {
              const grade = getGradeTag(filtered.levels);
              const subject = getSubjectTitleFromValue(
                filtered.subject?.toString() ?? "",
              );
              return {
                id: filtered.id,
                tags: [grade, "Arrangement", subject].filter(truthy),
                title: filtered.title ?? "",
                text: filtered.description?.join("") ?? "",
                image: filtered.cover,
                type: SearchCategory.Event,
                link: filtered.slug.full,
              };
            }) ?? []),

    podcasts:
      podcasts.data.value
        ?.filter(search("title", "description"))
        .map((filtered) => {
          const grade = getGradeTag(filtered.levels);
          const subject = getSubjectTitleFromValue(
            filtered.subject?.toString() ?? "",
          );
          return {
            tags: [grade, "Podkast", subject].filter(truthy),
            id: filtered.id,
            title: filtered.title ?? "",
            text: filtered.description ?? "",
            image: filtered.podcastCover,
            type: SearchCategory.Podcast,
            link: filtered.slug.full,
          };
        }) ?? [],
  };
});

const searchResult = computed<ResultItem[]>(() =>
  Object.values(results.value).flat(),
);

useHead({
  bodyAttrs: {
    class: "bg-white [--text:theme(colors.green.50)]",
  },
});
</script>

<template>
  <div>
    <LayoutBreadCrumbs
      :background="false"
      :text-conversions="{ sok: 'Søkeresultat' }"
      class="bg-green-20"
    />
    <main id="main-content" class="search">
      <h1>Søkeresultat</h1>
      <section class="search__research">
        <UiSearchField
          v-model="termValue"
          @submit.prevent="handleSearchInput"
          @focus="cleanSearch"
        >
          <label
            for="searchfield"
            :aria-label="`Fyll inn felt for å søke etter ord, nåværende søkord er: ${termValue}`"
            class="input__label"
          >
            Du søkte etter:
          </label>
        </UiSearchField>
      </section>
      <section class="search__result">
        <aside class="result__summary">
          <h2>Begrens søket:</h2>
          <nav>
            <ul class="summary__list">
              <li>
                <a
                  :href="`#${SearchCategory.Product}`"
                  :aria-label="`mengden funnet læremidler er: ${results.products.length}`"
                >
                  Læremidler
                </a>
                <span data-dd-privacy="mask">({{ results.products.length }})</span>
              </li>
              <li>
                <a
                  :href="`#${SearchCategory.Article}`"
                  :aria-label="`mengden funnet artikler er: ${results.articles.length}`"
                >
                  Artikler
                </a>
                <span data-dd-privacy="mask">({{ results.articles.length }})</span>
              </li>
              <li>
                <a
                  :href="`#${SearchCategory.Event}`"
                  :aria-label="`mengden funnet arrangementer er: ${results.events.length}`"
                >
                  Arrangementer
                </a>
                <span data-dd-privacy="mask">({{ results.events.length }})</span>
              </li>
              <li>
                <a
                  :href="`#${SearchCategory.Podcast}`"
                  :aria-label="`mengden funnet podkaster er: ${results.podcasts.length}`"
                >
                  Podkaster
                </a>
                <span data-dd-privacy="mask">({{ results.podcasts.length }})</span>
              </li>
            </ul>
          </nav>
        </aside>
        <div v-if="searchResult.length > 0" class="result__list">
          <h2 class="list__title">
            Resultat
          </h2>
          <NuxtLink
            v-for="(item, i) in searchResult"
            :id="searchResult[i - 1]?.type !== item.type ? item.type : ''"
            :key="item.id"
            :href="item.link"
            class="list__item"
            :aria-label="`relevante for følgende trinn og fag: ${item.tags.join(', ')} - ${item.title}`"
            data-dd-privacy="hidden"
          >
            <div class="item__container">
              <abbr class="container__tags">
                <span aria-hidden="true">{{ item.tags.join(" | ") }}</span>
              </abbr>
              <h3 class="container__title" v-text="item.title" />
              <div class="container__text" v-html="item.text" />
            </div>
            <img
              v-if="item.image?.img"
              :src="item.image.img"
              :alt="item.image.alt ?? ''"
              class="item__image"
            />
          </NuxtLink>
        </div>
        <div v-else class="result__no-results">
          <h3 class="headline">
            Å nei,
          </h3>
          <p>0 treff.</p>
          <p>Prøv å endre søkeord</p>
          <img
            src="/graphics/fallback_noresults.png"
            alt=""
            aria-hidden="true"
          />
        </div>
      </section>
    </main>
  </div>
</template>

<style scoped lang="scss">
.search {
  margin: 0;

  h1 {
    display: none;
  }

  @media screen and (min-width: $small) {
    margin: 0 auto;
  }

  &__research {
    background-color: theme("colors.green.20");
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .input__label {
      flex: 1;
      flex-basis: 100%;
      align-self: flex-start;
      max-width: theme("spacing.content-article");
      margin: 0 auto;
      font-weight: 600;
      font-size: pixelsToRem(18);
      color: theme("colors.green.40");
    }
  }

  &__result {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.8rem;
    max-width: theme("spacing.content");
    margin: 4rem 2rem;
    color: theme("colors.green.50");

    .result__no-results {
      width: 100%;
      max-width: theme("spacing.content");
      text-align: center;
      margin: 4rem auto;
      display: flex;
      flex-flow: column nowrap;
      gap: 2rem;

      .headline {
        font-size: pixelsToRem(90);
        color: theme("colors.red.40");
        font-weight: 400;
      }

      p {
        font-size: pixelsToRem(30);
        line-height: pixelsToRem(40);
        color: theme("colors.red.40");
        font-weight: 400;
        max-width: 32rem;
        margin: -1rem auto 0.5rem auto;
      }

      img {
        width: 100%;
        max-width: 30rem;
        margin: 0 auto;
      }
    }

    @media screen and (min-width: $small) {
      flex-flow: row nowrap;
      margin: 4rem auto 6rem auto;
    }

    .result__summary {
      flex: 1;

      h2 {
        text-transform: uppercase;
        font-size: pixelsToRem(16);
        letter-spacing: 0.02rem;
        font-weight: 600;
        border-bottom: 1px solid theme("colors.green.20");
        padding: 0 0 1rem 0;
        color: theme("colors.green.50");
      }

      .summary__list {
        padding: 0;
        margin: 0;

        li {
          font-size: pixelsToRem(16);
          margin: 1rem 0;
          display: flex;
          gap: 1rem;
          justify-content: space-between;
          border-bottom: 1px solid theme("colors.green.20");
          padding: 0 0 1rem 0;
          color: theme("colors.green.50");
        }
      }
    }

    .result__list {
      flex: 3;
      width: 100%;

      .list__title {
        text-transform: uppercase;
        font-size: pixelsToRem(16);
        letter-spacing: 0.02rem;
        font-weight: 600;
        border-bottom: 1px solid theme("colors.green.20");
        padding: 0 0 1rem 0;
        color: theme("colors.green.50");
      }

      .list__item {
        display: flex;
        flex-flow: row wrap;
        gap: 2.8rem;
        padding: 2rem 0;
        border-bottom: 1px solid theme("colors.green.20");
        text-decoration: none;

        &:hover {
          @media screen and (min-width: $small) {
            background-color: theme("colors.green.10");
            .item__container {
              transform: scale(0.99) translateX(0.6rem);
            }

            .item__image {
              transform: scale(0.98) translateX(-0.6rem);
            }
          }
        }

        .item__container {
          flex: 4;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin: 1rem 0;
          transition: all 0.3s ease-in-out;
          min-width: 20rem;

          .container__tags {
            font-size: pixelsToRem(12);
            color: theme("colors.green.50");
            text-transform: uppercase;
          }

          .container__title {
            font-size: pixelsToRem(18);
            color: theme("colors.green.40");
            font-weight: 600;
          }

          .container__text {
            // FIXME: Should use standard line-clamp when it's available
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .item__image {
          flex: 1;
          transition: all 0.3s ease-in-out;
          width: 100%;
          height: auto;
          align-self: center;

          @media screen and (min-width: $extra-small) {
            width: auto;
            max-width: 11rem;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
