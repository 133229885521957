function useQueryKey() {
  const version = useCrystallizeVersion();
  return ["/podkaster", version] as const;
}

export function usePodcasts() {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    staleTime: 1000 * 60 * 60, // 1 hour
    placeholderData: [],
    async queryFn({ queryKey }) {
      const data = await GqlGetCatalogue({
        language: "no-nb",
        path: queryKey[0],
        version: queryKey[1],
      });

      return data.catalogue?.children?.map(toPodcast) ?? [];
    },
  });
}

export function usePodcast(slug: MaybeRef<string>) {
  const queryKey = useQueryKey();

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [...queryKey, slug] as const,
    staleTime: 1000 * 60 * 10, // 10 minutes
    initialData() {
      const podcasts = queryClient.getQueryData<Podcast[]>(queryKey);
      return podcasts?.find((podcast) => podcast.slug.short === unref(slug));
    },
    initialDataUpdatedAt() {
      return queryClient.getQueryState(queryKey)?.dataUpdatedAt;
    },
    async queryFn({ queryKey }) {
      const data = await GqlGetPage({
        language: "no-nb",
        path: `${queryKey[0]}/${queryKey[2]}`,
        version: queryKey[1],
      });

      return data?.catalogue ? toPodcast(data.catalogue) : null;
    },
  });
}
