import type { CoreUserSource } from "#shared/types/core/external/session";
import merge from "defu";

function isNumber(value: number | undefined): value is number {
  return typeof value === "number";
}

export function taxConverter(item: Priceable) {
  const vat = (item.tax ?? 0) / 100;

  function withVat(exVat: ExVat): IncVat {
    return (exVat * (1 + vat)) as IncVat;
  }

  function withoutVat(incVat: IncVat): ExVat {
    return (incVat / (1 + vat)) as ExVat;
  }

  return {
    withVat,
    withoutVat,
  };
}

export function calculatePrices(
  item: Priceable,
  numberOfStudents: number,
  authSource: CoreUserSource | undefined,
  period: "initial" | "recurring" = "initial",
): CalculatedPrices {
  const { withoutVat } = taxConverter(item);

  function createDiscounted(
    discounted: IncVat | undefined,
    original: IncVat,
    isMetered: boolean,
  ): DiscountedPrice | undefined {
    if (!isNumber(discounted) || discounted >= original) {
      return undefined;
    }

    return {
      incVat: discounted,
      exVat: withoutVat(discounted),
      isMetered,
      percentage: Math.floor(((original - discounted) / original) * 100),
    };
  }

  const p = item.subscriptionsPlan?.period;

  let prices = merge(p?.prices?.[period], item.prices);

  if (authSource === "VIPPS") {
    if (!isNumber(prices.consumerPrice) && !item.isPurchasableByConsumers) {
      return "NOT_APPLICABLE";
    }

    // TODO: Check if we should use tiered pricing here. I don't think it makes sense.

    const incVat = prices.consumerPrice ?? prices.incVat ?? prices.default;
    const exVat = isNumber(prices.exVat) ? prices.exVat : withoutVat(incVat);

    const discounted = createDiscounted(
      prices.consumerDiscount ?? prices.discount,
      incVat,
      false,
    );

    const original = {
      incVat,
      exVat,
      isMetered: false,
    };

    return {
      original,
      discounted,
      actual: discounted ?? original,
    };
  }

  const tier = [...p?.meteredVariables?.[period] ?? []]
    .sort((a, b) => b.threshold - a.threshold)
    .find((tier) => tier.threshold < numberOfStudents);

  let isMetered = false;

  if (tier) {
    prices = merge(tier.prices, prices);
    isMetered = true;
  }

  const incVat = prices.incVat ?? prices.default;
  const exVat = isNumber(prices.exVat) ? prices.exVat : withoutVat(incVat);

  const discounted = createDiscounted(prices.discount, incVat, isMetered);

  const original = {
    incVat,
    exVat,
    isMetered,
  };

  return {
    original,
    discounted,
    actual: discounted ?? original,
  };
}

const VARIANT_IDENTIFIERS = [
  "default",
  "discount",
  "price-inc-vat",
  "price-ex-vat",
  "consumer-discount",
  "consumer-price",
] as const;

type VariantIdentifier = (typeof VARIANT_IDENTIFIERS)[number];

const KEY_MAPPING: Record<VariantIdentifier, keyof PriceVariants> = {
  "default": "default",
  "discount": "discount",
  "price-inc-vat": "incVat",
  "price-ex-vat": "exVat",
  "consumer-discount": "consumerDiscount",
  "consumer-price": "consumerPrice",
};

interface PriceVariant {
  identifier: string;
  price?: number | null;
}

export const ZERO_INC_VAT = 0 as IncVat;

/**
 * Convert a list of price variants to a more usable format.
 *
 * @param variants
 * @param basePrice - The base price to use if a variant is missing
 */
export function getPrices(
  variants: PriceVariant[] | null | undefined,
  basePrice = ZERO_INC_VAT,
): PriceVariants {
  return (variants ?? []).reduce<PriceVariants>(
    (prices, variant) => {
      const id = VARIANT_IDENTIFIERS.find((it) => it === variant.identifier);

      if (id && typeof variant.price === "number") {
        const key = KEY_MAPPING[id];
        if (key === "exVat") {
          prices[key] = variant.price as ExVat;
        } else {
          prices[key] = variant.price as IncVat;
        }

        if (id === "default" && basePrice && variant.price !== basePrice) {
          console.warn("default price variant was different to base price", {
            basePrice,
            variantPrice: variant.price,
          });
        }
      }

      return prices;
    },
    {
      default: basePrice,
    },
  );
}
