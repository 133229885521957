<script setup lang="ts">
import type { NuxtLinkProps } from "#app";

const { title, subtitle, description, tag, image, ...props } = defineProps<NuxtLinkProps & {
  title: string;
  subtitle?: string;
  description?: string;
  tag?: string;
  image?: ImageWithAltType;
}>();
</script>

<template>
  <NuxtLink
    v-bind="props"
    :aria-label="`Gå til ${title}`"
    class="group relative z-0 flex h-full min-h-60 flex-col overflow-hidden rounded-lg no-underline transition-all duration-300 ease-in-out hover:contrast-75"
  >
    <span
      v-if="tag"
      class="absolute z-[1] rounded-br-lg rounded-tl-lg bg-white px-5 py-2 text-xs uppercase text-black-80"
      v-text="tag"
    />
    <figure class="max-h-32 min-h-32 w-full overflow-hidden">
      <img
        v-if="image?.img"
        :src="image.img"
        :alt="image.alt ?? ''"
        class="w-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-105"
      />
    </figure>
    <div class="flex flex-[1] items-center justify-between gap-m px-[20px] py-s text-white">
      <div class="flex flex-col gap-xs">
        <div class="flex flex-col">
          <span class="text-lg font-semibold leading-6" v-text="title" />
          <span
            v-if="subtitle"
            class="text-sm font-semibold"
            v-text="subtitle"
          />
        </div>
        <span
          v-if="description"
          class="text-sm"
          v-text="description"
        />
      </div>
      <slot name="icon" />
    </div>
  </NuxtLink>
</template>
