<script setup lang="ts">
import type { NuxtLinkProps } from "#app";

const props = defineProps<NuxtLinkProps>();

function handleClick() {
  if (useRoute().path === props.to) {
    useRouter().go(0);
  }
}
</script>

<template>
  <li>
    <NuxtLink
      data-link
      class="grid items-center gap-x-7 py-6 text-2xl text-white no-underline hover:text-yellow-40 xs:text-sidebar-link [&>span]:w-full"
      v-bind="props"
      @click.prevent="handleClick"
    >
      <slot />
    </NuxtLink>
  </li>
</template>

<style scoped>
[data-link]:has(> svg) {
  grid-template-columns: 1fr 5fr;
}
</style>
